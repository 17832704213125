import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Col, Image, Navbar, Nav, NavItem, Container } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useLocation } from 'react-router-dom';
import { routeMap } from '../helper/routes';
import { TabsState, UserState } from 'cdfdigital-common/src/models';
import NavIcon from './NavIcon';
import { FiMenu, FiX } from 'react-icons/fi';

export interface HeaderProps {
  title?: string;
}

const Header: React.FC<HeaderProps> = () => {
  const [openHead, setOpenHead] = useState(false);
  // static defaultProps = {
  //   login: null,
  //   title: '',
  // };

  const location = useLocation();

  const { slug } = useSelector<any, any>((state) => state.labelReducer);

  const { data: tabs } = useSelector<any, TabsState>(
    (state) => state.tabsReducer,
  );

  const { data: user } = useSelector<any, UserState>(
    (state) => state.userReducer,
  );

  const onClick = () => {
    setOpenHead(!openHead);
  };

  const renderItems = (item: any, link: string) => (
    <LinkContainer to={`/main${link}`} key={item._id}>
      <NavItem onClick={onClick}>
        <Col
          xs={12}
          className={
            location.pathname.includes(link)
              ? 'menu-item selected'
              : 'menu-item'
          }
        >
          <NavIcon iconName={item.iconName} iconType="bootstrap" />
          <span className="item-name">{item.title}</span>
        </Col>
      </NavItem>
    </LinkContainer>
  );

  return (
    <Navbar id="component-header" expand="lg" expanded={openHead}>
      <Container>
        <div className="navbar-header">
          <Navbar.Brand className={user ? 'user' : 'no-user'}>
            <Image src={`/${slug}/logo.png`} />
          </Navbar.Brand>
          {slug && ['tokiomarine'].includes(slug) ? (
            <Navbar.Brand className="second-logo">
              <Image src={`/${slug}/logo2.png`} />
            </Navbar.Brand>
          ) : null}
          {user && (
            <Navbar.Toggle onClick={onClick}>
              {openHead ? <FiX /> : <FiMenu />}
            </Navbar.Toggle>
          )}
          <Nav className="navbar-right">
            <Image src={`/header-powered.png`} />
          </Nav>
        </div>
        <Navbar.Collapse>
          <Container>
            <Nav>
              {tabs.map((item: any) => renderItems(item, routeMap[item.route]))}
            </Nav>
          </Container>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
