import React, { useRef } from 'react';
import { FaCopy } from 'react-icons/fa';
import Markdown from 'react-markdown';
import { isIOS, isAndroid, isBrowser, deviceDetect } from 'react-device-detect';
import copyToClipboard from '../../../helper/copy-to-clipboard';
import { routeMap } from '../../../helper/routes';
import escapeStringRegexp from 'escape-string-regexp';
import { User } from 'cdfdigital-common/build/models';
import { Link } from 'react-router-dom';

interface ToolsItemMarkdownProps {
  user: User;
  medexToken: string;
}

const ToolsItemMarkdown: React.FC<ToolsItemMarkdownProps> = ({
  user,
  children,
  medexToken,
}) => {
  const codeRef = useRef<any>();

  /*
  Exemplo: ![$CD_ATIVACAO_MCAFEE]()
    Procurará dentro de CONTRATO[i]
    o objeto contendo a propriedade CD_ATIVACAO_MCAFEE
    e retornará o valor dessa propriedade
  */
  const getSoftwareKey = (
    softwareNameKey: any,
    softwareNameMatch: any,
    keyName: any,
  ) => {
    const keys = user.CONTRATO.map((contrato: any) =>
      contrato.SOFTWARES.find((item: any) => {
        const regex = new RegExp(escapeStringRegexp(softwareNameMatch));
        return item[softwareNameKey] && item[softwareNameKey].match(regex);
      }),
    ).filter((x: any) => x);
    return keys[0] ? keys[0][keyName] : null;
  };

  const getKey = (keyName: string) => {
    const key = user.CONTRATO.find((contrato: any) => contrato[keyName]);
    // @ts-ignore
    return key ? key[keyName] : null;
  };

  const renderCode = (code: any) => {
    return (
      <div ref={codeRef} className="code">
        {code}
        <button
          className="btn-copy"
          onClick={() => {
            copyToClipboard(code);
            //setCopySuccess('Copied!');
          }}
        >
          <FaCopy className="icon" />
        </button>
      </div>
    );
  };

  const getTextForPlatform = (text: string) => {
    const device = deviceDetect(undefined);
    if (text.endsWith('#ANDROID')) {
      return isAndroid && text.replace('#ANDROID', '');
    }
    if (text.endsWith('#IOS')) {
      return isIOS && text.replace('#IOS', '');
    }
    if (text.endsWith('#MAC')) {
      return (
        isBrowser && device.osName === 'Mac OS' && text.replace('#MAC', '')
      );
    }
    if (text.endsWith('#WINDOWS')) {
      return (
        isBrowser && device.osName === 'Windows' && text.replace('#WINDOWS', '')
      );
    }
    return text; // all other link texts are unchanged
  };

  const renderers = () => {
    let renderers = {
      link: (props: any) => {
        let { href, children } = props;

        let target = '_blank';
        let fone = '';

        if (href.startsWith('$')) {
          href = getKey(href.replace('$', ''));
        } else if (!href.includes('://')) {
          href = '/main' + routeMap[href];
          target = '_self';
        } else if (href.includes('medexapp')) {
          // CDF-109
          href += user.NR_CPF;
          target = '_blank';
        } else if (href.includes('medex.net.br')) {
          user.LISTA_TELEFONE.forEach((value) => {
            if (value.NR_FONE.length === 9) {
              fone = '55' + value.NR_DDD + value.NR_FONE;
            }
          });

          href += `phone=${fone}&cpf=${user.NR_CPF}&token=${medexToken}`;
          target = '_blank';
        }
        href = href?.replace('#webview', ''); // webview, not needed on the web version
        let text = getTextForPlatform(children[0]);
        if (text) {
          if (href.includes('://')) {
            return (
              <a href={href} className="btn btn-secondary" target={target}>
                {text}
              </a>
            );
          }
          return (
            <Link to={href} className="btn btn-secondary">
              {text}
            </Link>
          );
        }
        return null;
      },
      image: (props: any) => {
        if (props.alt.startsWith('#')) {
          const softwareInfo = props.alt.replace('#', '').split('/');
          const softwareNameKey = softwareInfo[0];
          const softwareNameMatch = softwareInfo[1];
          return renderCode(
            getSoftwareKey(softwareNameKey, softwareNameMatch, props.src),
          );
        }
        if (props.alt.startsWith('$')) {
          const softwareKey = props.alt.replace('$', '');
          return renderCode(getKey(softwareKey));
        }
        return <img {...props} alt="" />;
      },
      paragraph: (props: any) => {
        return <p {...props} />;
      },
    };

    return renderers;
  };

  return <Markdown renderers={renderers()}>{children}</Markdown>;
};

export default ToolsItemMarkdown;
