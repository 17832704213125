import React from 'react';
import { useLocation } from 'react-router-dom';

const Chat: React.FC = () => {
  const location = useLocation<any>();

  return <iframe id="container-chat" title="chat" src={location.state.src} />;
};

export default Chat;
