import React, { useCallback, useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import axios from 'axios';
import { ContentContainer, ErrorOverlay } from '../../../components';
// import BigPicture from "react-bigpicture";
import BigPicture from '../../../bigpicture'; // TMP fix https://github.com/gigantz/react-bigpicture/issues/9
import { Video } from 'cdfdigital-common/src/models';
import { fetchDurations } from 'cdfdigital-common/build/helper/youtube';
import { Duration } from 'cdfdigital-common/build/models';

const { REACT_APP_YOUTUBE_API_KEY: YOUTUBE_API_KEY } = process.env;
const YOUTUBE_API_URL = 'https://www.googleapis.com/youtube/v3';

const NewsYoutube: React.FC = () => {
  const [error, setError] = useState<any>(null);
  const [fetching, setFetching] = useState<boolean>(true);
  const [videos, setVideos] = useState<Video[]>([]);
  const [durations, setDurations] = useState<Duration>({});

  const fetchPlaylistData = useCallback(() => {
    const playListUrl =
      YOUTUBE_API_URL +
      '/playlistItems?' +
      'part=snippet&playlistId=PLNCXxjH66-Yxz8iSP_SH9AK7_ExYH6tzC&key=' +
      YOUTUBE_API_KEY;

    axios
      .get(playListUrl)
      .then(async (response) => {
        const durations = await fetchDurations(response.data.items, {
          YOUTUBE_API_URL,
          YOUTUBE_API_KEY,
        });
        setDurations(durations);
        setFetching(false);
        setVideos(response.data.items);
      })
      .catch((err) => {
        console.error(err);
        setError(err);
        setFetching(false);
      });
  }, []);

  useEffect(() => {
    fetchPlaylistData();
  }, [fetchPlaylistData]);

  const cardContent = (item: any) => {
    const { thumbnails, resourceId } = item.snippet;
    // const image = thumbnails.standard ? thumbnails.standard.url :
    //   (thumbnails.high ? thumbnails.high.url : thumbnails.default.url);
    // @ts-ignore
    const image = Object.values(thumbnails).pop().url;
    const link = 'https://www.youtube.com/watch?v=' + resourceId.videoId;
    return (
      <Col xs={12} sm={6} className="video-item" key={resourceId.videoId}>
        <BigPicture type="youtube" src={link}>
          <div
            className="video-image"
            style={{ backgroundImage: `url(${image})` }}
          >
            <div className="duration">{durations[resourceId.videoId]}</div>
          </div>
        </BigPicture>
      </Col>
    );
  };

  const renderNews = () => {
    if (fetching) {
      return null;
    }
    return videos
      .filter((item: any) => item.snippet.thumbnails)
      .map((item: any) => cardContent(item));
  };

  return (
    <ContentContainer id="container-news">
      <Row>{renderNews()}</Row>
      <ErrorOverlay
        visible={error !== null}
        onClose={() => fetchPlaylistData()}
        title="Erro"
        buttonTitle="Tentar novamente"
        message="Ocorreu um erro. Verifique a sua conexão e tente novamente."
      />
    </ContentContainer>
  );
};

export default NewsYoutube;
