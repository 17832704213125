import { axiosAuth } from '../helper/axios-auth';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { AccessToken } from 'cdfdigital-common/src/models/AccessToken';
import { AuthInfoState } from 'cdfdigital-common/src/models';

export const getAccessToken = createAsyncThunk(
  `access-token/generate`,
  async (
    { data: { identifier, phone } }: AuthInfoState,
    { rejectWithValue, getState },
  ) => {
    try {
      const { labelReducer } = getState() as any;
      const axios = await axiosAuth();
      const idParam = 'NR_CPF';
      const response = await axios.post<AccessToken>(
        '/auth/generate-access-token',
        {
          [idParam]: identifier,
          ID_CLIENTE_CORPORATIVO: labelReducer.ID_CLIENTE_CORPORATIVO,
          NR_PHONE: phone,
          label: labelReducer.slug,
          platform: 'web',
        },
      );
      return response.data;
    } catch (e) {
      const error = accessTokenError(e as AxiosError);
      return rejectWithValue(error);
    }
  },
);

interface ErrorMessage {
  message?: string;
  status?: number | null;
}

const accessTokenError = (axiosError: AxiosError): ErrorMessage => {
  const magaluCPFMessage =
    'VERIFIQUE SE O CPF DO TITULAR DO SERVIÇO E O TELEFONE FORAM PREENCHIDOS CORRETAMENTE OU CLIQUE EM CENTRAL DE ATENDIMENTO E FALE COM UM DE NOSSOS ESPECIALISTAS';

  const isMagalu = false; //env().APP_LABEL === 'magalu';

  const genericError = isMagalu
    ? magaluCPFMessage
    : 'Login Inválido! Tente novamente.';

  const errors: { [key: number]: string } = {
    401: genericError, // CPF não existe ou sem contrato
    402: 'O seu contrato não inclui acesso ao aplicativo.', // Sem produtos permitidos
    406: genericError, // Chave inválida
    // 409: 'Chave de acesso expirada.', // Pincode já usado
    422: genericError, // Cadastro inválido, retornado pelo Taki
  };

  if (axiosError.response) {
    const status = axiosError.response.status;

    return {
      message: errors[status],
      status,
    };
  }

  if (axiosError.message === 'Network Error') {
    return {
      message: 'Verifique a sua conexão e tente novamente.',
      status: null,
    };
  }

  return {
    message: 'Ocorreu um erro. Tente novamente mais tarde.',
    status: 500,
  };
};
