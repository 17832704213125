import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Button, Modal, Image } from 'react-bootstrap';
import Markdown from 'react-markdown';
import {
  fetchTools,
  fetchLuckyNumber,
  fetchLuckyNumberDescription,
} from 'cdfdigital-common/build/actions';
import { Icon, ContentContainer, ErrorOverlay } from '../../../components';
import appendQueryString from 'cdfdigital-common/build/helper/append-query-string';
import { Link, useHistory } from 'react-router-dom';
import {
  LuckyNumberDescriptionState,
  LuckyNumberState,
  ToolsState,
} from 'cdfdigital-common/src/models';

const LuckyNumber: React.FC = () => {
  const history = useHistory();

  const [showInfo, setShowInfo] = useState(false);

  const [toolsItem, setToolsItem] = useState<any | null>(null);

  const {
    data: luckyNumber,
    fetching: luckyNumberFetching,
    error: luckyNumberError,
  } = useSelector<any, LuckyNumberState>((state) => state.luckyNumberReducer);

  const {
    data: { description, regulation },
    fetching: descriptionFetching,
    error: descriptionError,
  } = useSelector<any, LuckyNumberDescriptionState>(
    (state) => state.luckyNumberDescriptionReducer,
  );

  const {
    data: tools,
    fetching: toolsFetching,
    error: toolsError,
  } = useSelector<any, ToolsState>((state) => state.toolsReducer);

  const fetching = toolsFetching || luckyNumberFetching || descriptionFetching;
  const error = toolsError || luckyNumberError || descriptionError;

  const dispatch = useDispatch();

  const init = useCallback(() => {
    dispatch(fetchLuckyNumberDescription());
    dispatch(fetchLuckyNumber());

    if (!tools.length) {
      fetchTools();
    } else {
      setToolsItem(
        tools.find((item: any) => item.id_slug === 'tool_lucky_number'),
      );
    }
  }, [dispatch, tools]);

  useEffect(() => {
    init();
  }, [init]);

  useEffect(() => {
    if (tools.length) {
      setToolsItem(
        tools.find((item: any) => item.id_slug === 'tool_lucky_number'),
      );
    }
  }, [tools]);

  if (fetching || !toolsItem) {
    return (
      <ContentContainer id="container-toolsItem">
        Carregando...
      </ContentContainer>
    );
  }

  return (
    <ContentContainer
      id="container-toolsItem"
      header={
        <div className="header">
          <Image
            className="image"
            fluid
            src={appendQueryString(
              toolsItem.image,
              'w=1200&h=600&fit=crop&blend=000000&bm=darken&balph=30',
            )}
          />
          <div className="header-info">
            <h2>{toolsItem.name}</h2>
          </div>
        </div>
      }
    >
      <Row>
        <Col xs={12} className="content">
          <h1>
            <Icon className="hidden-xs" name="trophy-award" />
            Número da Sorte
          </h1>

          <Markdown>{description}</Markdown>

          {Boolean(luckyNumber) && <h4 className="code">{luckyNumber}</h4>}

          <Button
            className="margin-y-3 btn-secondary"
            variant="primary"
            onClick={() => {
              setShowInfo(true);
            }}
          >
            Regulamento da Capitalização
          </Button>
          <div>
            <Link to="/main/service-channels" className="btn btn-secondary">
              Central de Atendimento
            </Link>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="back-btn btn-center">
          <button
            onClick={() => history.goBack()}
            className="btn btn-secondary"
          >
            Voltar
          </button>
        </Col>
      </Row>
      <Modal show={showInfo} onHide={() => {}}>
        <Modal.Header>
          <Modal.Title>Regulamento da Capitalização</Modal.Title>
        </Modal.Header>
        <Modal.Body className="white-space-pre">{regulation}</Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => {
              setShowInfo(false);
            }}
          >
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
      <ErrorOverlay
        visible={error}
        onClose={init}
        buttonTitle="Tentar Novamente"
        title="Erro"
        message="Ocorreu um erro. Verifique a sua conexão e tente novamente."
      />
    </ContentContainer>
  );
};

export default LuckyNumber;
