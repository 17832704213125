import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Modal, Button, FormSelect } from 'react-bootstrap';
import { FormInput } from '../../../components';
import normalization from 'cdfdigital-common/build/helper/normalization';
import validation from 'cdfdigital-common/build/helper/validation';
import { createCallMe } from 'cdfdigital-common/build/actions';
import { ContentContainer, HeaderIcon } from '../../../components';
import { useHistory } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { UserState } from 'cdfdigital-common/src/models';

const CallMe: React.FC = () => {
  const history = useHistory();

  const [messageSent, setMessageSent] = React.useState(false);

  const time = [
    { label: 'Manhã', value: 'manha' },
    { label: 'Tarde', value: 'tarde' },
    { label: 'Noite', value: 'noite' },
  ];

  const { fetching, error: serviceError } = useSelector<any, any>(
    (state) => state.serviceChannelsReducer,
  );
  const { data: user } = useSelector<any, UserState>(
    (state) => state.userReducer,
  );

  const tel = user ? user.LISTA_TELEFONE[0] : '';

  const initialValues = {
    tel: tel ? normalization.tel(tel.NR_DDD + tel.NR_FONE) : '',
    time: 'manha',
  };

  const dispatch = useDispatch();

  const onSubmit = ({ tel, time }: any) => {
    setMessageSent(true);
    dispatch(createCallMe({ tel, time }));
  };

  const back = () => {
    history.replace('/main/service-channels');
  };

  const dismiss = () => {
    setMessageSent(false);
  };

  const {
    handleSubmit,
    clearErrors,
    control,
    formState: { errors },
    setValue,
  } = useForm<any>({
    defaultValues: initialValues,
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  return (
    <ContentContainer>
      <div className="icon-container">
        <HeaderIcon name={'autorenew'} />
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col xs={12}>
            <h2 className="page-title">Ligue-me</h2>
            <h4 className="page-subtitle">
              Nós podemos te ligar, só precisamos de algumas informações:
            </h4>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <div className="form-group">
              <Controller
                name="tel"
                control={control}
                rules={{
                  validate: validation.telLength,
                }}
                render={({ field }) => (
                  <FormInput
                    input={field}
                    name="tel"
                    // label="Telefone"
                    placeholder="Telefone"
                    maxLength={15}
                    meta={{
                      error: errors.tel,
                      touched: false,
                      submitFailed: '',
                    }}
                    normalize={normalization.tel}
                    clearErrors={clearErrors}
                    setValue={setValue}
                  />
                )}
              />
            </div>
          </Col>
          <Col xs={12}>
            <div className="form-group">
              <label htmlFor="time">Período</label>
              <Controller
                name="time"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <FormSelect
                    name="time"
                    //label="Periodo"
                    //componentClass="select"
                  >
                    {time.map(({ label, value }, key) => (
                      <option key={key} value={value}>
                        {label}
                      </option>
                    ))}
                  </FormSelect>
                )}
              />
              <br />
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="btn-center" xs={12}>
            <Button
              type="submit"
              className="btn-primary margin-y-2"
              disabled={messageSent}
            >
              {messageSent ? 'Enviando...' : 'Continuar'}
            </Button>
          </Col>
        </Row>
      </form>
      <Modal show={messageSent && !fetching} onHide={() => {}}>
        <Modal.Header>
          <Modal.Title>{!serviceError ? 'Confirmação' : 'Erro'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>
            {!serviceError
              ? 'Seu pedido de contato foi enviado! Aguarde até entrarmos em contato.'
              : 'Houve um erro com o seu pedido, tente novamente mais tarde.'}
          </h4>
        </Modal.Body>
        <Modal.Footer>
          <Button
            bsPrefix="btn btn-primary"
            onClick={serviceError === null ? back : dismiss}
          >
            Confirmar
          </Button>
        </Modal.Footer>
      </Modal>
    </ContentContainer>
  );
};

export default CallMe;
