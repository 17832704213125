import { axiosAuth } from '../helper/axios-auth';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

interface TryLoginArgs {
  identifier: string;
  token: string;
}

export const tryLogin = createAsyncThunk(
  `login/try`,
  async (
    { identifier, token }: TryLoginArgs,
    { rejectWithValue, getState },
  ) => {
    try {
      const { labelReducer } = getState() as any;
      const axios = await axiosAuth();
      const idParam = 'NR_CPF';
      const response = await axios.post('/auth', {
        [idParam]: identifier,
        ID_CLIENTE_CORPORATIVO: labelReducer.ID_CLIENTE_CORPORATIVO,
        label: labelReducer.slug,
        platform: 'web',
        token,
      });
      localStorage.setItem('token', response.data.token);
      localStorage.setItem('userId', identifier);
      return response.data;
    } catch (e) {
      const error = loginError(e as AxiosError);
      return rejectWithValue(error);
    }
  },
);

interface LoginArgs {
  token: string;
}

export const logIn = createAsyncThunk(`login`, async ({ token }: LoginArgs) => {
  localStorage.setItem('token', token);
  return token;
});

export const logOut = createAsyncThunk(`logout`, async () => {
  await localStorage.removeItem('token');
  await localStorage.removeItem('userId');
});

interface ErrorMessage {
  message?: string;
  status?: number | null;
}

const loginError = (error: AxiosError): ErrorMessage => {
  if (error.response) {
    const errors: { [key: number]: string } = {
      401: 'Código de acesso inválido', // Token inválido
      409: 'Código de acesso expirado.', // Token expirado
    };

    const { status } = error.response;

    return {
      message: errors[status],
      status,
    };
  }

  if (error.message === 'Network Error') {
    return {
      message: 'Verifique a sua conexão e tente novamente.',
      status: null,
    };
  }

  return {
    message: 'Ocorreu um erro. Tente novamente mais tarde.',
    status: 500,
  };
};
