import { getAccessToken } from '../actions/accessTokenActions';
import { createSlice } from '@reduxjs/toolkit';
import { AccessTokenState } from 'cdfdigital-common/src/models/AccessToken';

const initialState: AccessTokenState = {
  fetching: false,
  data: null,
  error: null,
};

const accessTokenSlice = createSlice({
  name: 'access-token',
  initialState,
  reducers: {
    clearState: (state) => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(getAccessToken.pending, (state) => {
        state.data = null;
        state.error = null;
        state.fetching = true;
      })
      .addCase(getAccessToken.fulfilled, (state, action) => {
        state.data = action.payload;
        state.error = null;
        state.fetching = false;
      })
      .addCase(getAccessToken.rejected, (state, action) => {
        state.data = null;
        state.error = action.payload || action.error;
        state.fetching = false;
      });
  },
});

export const accessTokenReducer = accessTokenSlice.reducer;
export const accessTokenActions = accessTokenSlice.actions;
