const copyToClipboard = (code: string) => {
  try {
    const textarea = document.createElement('textarea');
    document.body.appendChild(textarea);
    textarea.value = code;
    textarea.select();
    textarea.setSelectionRange(0, 9999);
    document.execCommand('copy');
    document.body.removeChild(textarea);
  } catch (err) {
    const error = err as Error;
    alert('Falha ao copiar código: ' + error.message);
  }
};

export default copyToClipboard;
