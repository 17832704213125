import React from 'react';
import { useSelector } from 'react-redux';
import News from './News';
import NewsYoutube from './Youtube';
import NewsItem from './NewsItem';
import { Route, useRouteMatch } from 'react-router-dom';

const NewsRoutes: React.FC = () => {
  const match = useRouteMatch();

  const { slug } = useSelector<any, any>((state) => state.labelReducer);

  return (
    <div>
      <Route
        exact
        path={`${match.url}/`}
        component={slug === 'magalu' ? NewsYoutube : News}
      />
      <Route path={`${match.url}/:id`} component={NewsItem} />
    </div>
  );
};

export default NewsRoutes;
