export const routeMap: { [key: string]: string } = {
  News: '/news',
  ServiceChannels: '/service-channels',
  Schedule: '/schedule',
  Tools: '/tools',
  ToolsItem: '/tools', // Modification to fit new-key value pair design
  StoreLocation: '/store-location',
  Account: '/account',
  Media: '/media',
};
