import { fetchLicense, fetchUser } from 'cdfdigital-common/build/actions';
import { Contrato, Tool, User } from 'cdfdigital-common/build/models';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const SOFTWARE_NAMES = [
  '123 Protect',
  'Looke',
  'Kaspersky Internet Security',
  'Kaspersky Safe Kids',
];

interface IProps {
  toolsItem: any;
  user: User | undefined;
}

interface Return {
  loading: boolean;
}

/**
 * Activate license if it's missing for the current toolsItem,
 * then refetch user data.
 *
 * Conditions:
 *   If current toolsItem on user.CONTRATO[0].SOFTWARES doesn't have DS_PRODUCT_KEY
 *   and if DS_SOFTWARE belongs to SOFTWARE_NAMES
 */
export const useLicense = ({ toolsItem, user }: IProps): Return => {
  const [loading, setLoading] = useState(true);
  const missingLicenseRef = useRef<any[]>([]);
  const dispatch = useDispatch();

  const { data: license } = useSelector<any, any>(
    (state) => state.licenseReducer,
  );

  // Checks if there are softwares on user.CONTRATO without license
  useEffect(() => {
    if (!toolsItem || !user || missingLicenseRef.current.length > 0) return;
    const missingLicense = checkMissingLicense(user.CONTRATO, toolsItem);

    if (!missingLicense) {
      setLoading(false);
      return;
    }

    // Activates license if software without license is found on user.CONTRATO
    missingLicenseRef.current = missingLicense;
    dispatch(fetchLicense(missingLicense));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toolsItem, user]);

  // Refetch user data after activating license
  useEffect(() => {
    if (
      missingLicenseRef.current.length > 0 &&
      license?.RESULT === 'OK' &&
      !license?.ERRO
    ) {
      dispatch(fetchUser());
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [license]);

  return { loading };
};

/**
 * Return Software[] or undefined
 */
const checkMissingLicense = (
  contratos: Contrato[],
  toolsItem: Tool,
): any[] | undefined => {
  const name = getSoftwareNameFromContent(toolsItem);

  if (!name || !SOFTWARE_NAMES.includes(name)) {
    return;
  }

  const missingLicenses = contratos.map((contrato) =>
    contrato.SOFTWARES.filter((item) => {
      return (
        !item.DS_PRODUCT_KEY &&
        (item.DS_SOFTWARE === name || item.DS_SOFTWARE.includes(name))
      );
    }),
  );

  if (missingLicenses[0].length > 0) {
    return missingLicenses[0];
  }
};

/**
 * Gets software name on markdown content using pattern [#DS_SOFTWARE/Software name]
 */
const getSoftwareNameFromContent = (toolsItem: Tool): string | undefined => {
  const softwareNameMatch = toolsItem.markdown.match(/\[.*DS_SOFTWARE\/(.*)\]/);

  if (softwareNameMatch !== null) {
    return softwareNameMatch[1];
  }
};
