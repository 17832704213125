import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Image } from 'react-bootstrap';
import appendQueryString from 'cdfdigital-common/build/helper/append-query-string';
import { fetchTools } from 'cdfdigital-common/build/actions';
import { ContentContainer } from '../../../components';
import WifiGMap from './WifiGMap';
import SpotifyItem from './SpotifyItem';
import { Link, useRouteMatch } from 'react-router-dom';
import { ToolsState, UserState } from 'cdfdigital-common/src/models';
import ToolsItemMarkdown from './ToolsItemMarkdown';
import { useLicense } from './helpers/useLicense';
import { getMedexToken } from './helpers/medexToken';
import detectCurrentLabel from '../../../helper/label';

const ToolsItem: React.FC = () => {
  const match = useRouteMatch<any>();
  const [toolsItem, setToolsItem] = useState<any | null>(null);

  const { data: tools } = useSelector<any, ToolsState>(
    (state) => state.toolsReducer,
  );

  const { data: user, fetching: userFetching } = useSelector<any, UserState>(
    (state) => state.userReducer,
  );

  const [medexToken, setToken] = useState('');

  const { slug } = useSelector<any, any>((state) => state.labelReducer);

  const { loading: licenseLoading } = useLicense({
    toolsItem,
    user,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);

    if (!tools.length) {
      dispatch(fetchTools());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Set var toolsItem
  useEffect(() => {
    if (tools.length) {
      const toolsItem = tools.find(
        (item: any) => item.id_slug === match.params.id_slug,
      );
      setToolsItem(toolsItem);
    }
  }, [tools, match]);

  // Get medex token
  useEffect(() => {
    if (
      detectCurrentLabel() === 'ticket' ||
      detectCurrentLabel() === 'tokiomarine'
    ) {
      getMedexToken().then((token) => {
        setToken(token);
      });
    }
  }, []);

  if (
    !toolsItem ||
    userFetching ||
    !user ||
    licenseLoading ||
    (!medexToken && detectCurrentLabel() === 'ticket') ||
    (!medexToken && detectCurrentLabel() === 'tokiomarine')
  ) {
    return (
      <ContentContainer id="container-toolsItem">
        <Row>
          <Col xs={12}>Carregando...</Col>
        </Row>
      </ContentContainer>
    );
  }

  return (
    <ContentContainer
      id="container-toolsItem"
      header={
        <div className="header">
          <Image
            className="image"
            fluid
            src={appendQueryString(
              toolsItem.image,
              `w=1200&h=600&fit=crop&blend=000000${
                toolsItem.id_slug === 'tool_spotify' ? `` : `&bm=darken`
              }&balph=30`,
            )}
          />
          <div className="header-info">
            <h2>
              {toolsItem.id_slug === 'tool_spotify' ? '' : toolsItem.name}
            </h2>
          </div>
        </div>
      }
      className={toolsItem.id_slug}
    >
      <Row>
        <Col className="content" xs={12}>
          {toolsItem.id_slug === 'tool_spotify' ? (
            <SpotifyItem />
          ) : (
            <ToolsItemMarkdown user={user} medexToken={medexToken}>
              {toolsItem.markdown}
            </ToolsItemMarkdown>
          )}
        </Col>
        {slug === 'magalu' && toolsItem.id_slug === 'tool_wifi' ? (
          <WifiGMap />
        ) : (
          ''
        )}
      </Row>
      <Row>
        <Col xs={12} className="back-btn btn-center">
          <Link to="/main/tools" className="btn btn-secondary">
            Voltar
          </Link>
        </Col>
      </Row>
    </ContentContainer>
  );
};

export default ToolsItem;
