import React, { useEffect } from 'react';
import { Row, Col, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setPageTitle } from '../../../actions';
import { fetchMedia } from 'cdfdigital-common/build/actions';
import appendQueryString from 'cdfdigital-common/build/helper/append-query-string';
import { ErrorOverlay, ContentContainer } from '../../../components';
import { MediaState } from 'cdfdigital-common/src/models';

const Media: React.FC = () => {
  const {
    data: media,
    fetching,
    error,
  } = useSelector<any, MediaState>((state) => state.mediaReducer);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setPageTitle('Novidades'));
    dispatch(fetchMedia());
  }, [dispatch]);

  const retry = () => {
    dispatch(fetchMedia());
  };

  const cardContent = (item: any) => (
    <Row className="item-row no-gutters">
      <Col xs={12} sm={5}>
        <Image
          fluid
          src={appendQueryString(
            item.image,
            'w=750&h=450&fit=crop&blend=000000&bm=darken&balph=30',
          )}
        />
        {item.tags && <div className="news-label">{item.tags}</div>}
      </Col>
      <Col className="item-info" xs={12} sm={7}>
        <h3 className="title">{item.title}</h3>
        <p>{item.description}</p>
      </Col>
    </Row>
  );

  const renderMedia = () => {
    if (fetching) {
      return null;
    }
    return media.map((item: any) => {
      return (
        <Link key={item._id} to={`/main/media/${item._id}`}>
          {cardContent(item)}
        </Link>
      );
    });
  };

  return (
    <ContentContainer id="container-news" header="Mídia">
      {renderMedia()}
      <ErrorOverlay
        visible={error !== null}
        onClose={retry}
        title="Erro"
        buttonTitle="Tentar novamente"
        message="Ocorreu um erro. Verifique a sua conexão e tente novamente."
      />
    </ContentContainer>
  );
};

export default Media;
