import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import { tryLogin, setPageTitle } from '../actions';
import { FormInput, ErrorOverlay, Header, HeaderIcon } from '../components';
import normalization from 'cdfdigital-common/build/helper/normalization';
import validate from 'cdfdigital-common/build/helper/validation';
import { LoginState } from 'cdfdigital-common/src/models/Login';
import { AuthInfoState } from 'cdfdigital-common/src/models';
import { Controller, useForm } from 'react-hook-form';

const AccessToken: React.FC = () => {
  const history = useHistory();

  // state = {
  //   loginRejected: false,
  //   pincode: undefined,
  // };

  const [loginRejected, setLoginRejected] = useState(false);

  const { data: authInfo } = useSelector<any, AuthInfoState>(
    (state) => state.authInfoReducer,
  );

  const {
    data: { token },
    fetching,
    error: loginError,
  } = useSelector<any, LoginState>((state) => state.loginReducer);

  interface FormValues {
    identifier: string;
    code: string;
  }

  const initialValues: FormValues = {
    identifier: normalization.cpf(authInfo.identifier),
    code: '',
  };

  const dispatch = useDispatch();

  useEffect(() => {
    if (!authInfo.identifier) {
      history.push('login');
    }
    dispatch(setPageTitle('Login'));
  }, [authInfo, history, dispatch]);

  useEffect(() => {
    if (loginError) {
      setLoginRejected(true);
    }
    if (token) {
      history.push('/main/home');
    }
  }, [loginError, token, history]);

  const onSubmit = (values: any) => {
    // const { pincode } = this.props;
    // const { identifier, code } = values;
    // tryLogin(identifier.replace(/\D/g, ''), code.replace(/\D/g, ''), pincode);
    const { identifier, code } = values;
    dispatch(
      tryLogin({
        identifier: identifier.replace(/\D/g, ''),
        token: code,
      }),
    );
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    clearErrors,
  } = useForm<any>({
    defaultValues: initialValues,
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  return (
    <div id="container-login">
      <Header />
      <div id="container-access-token" className="container-bg-fluid">
        <div className="container-bg-left">
          <div className="container-bg-menu">
            <div className="icon-container">
              <HeaderIcon name={'account-outline'} />
            </div>
          </div>
        </div>
        <div className="container-bg-right">
          <Col
            xs={12}
            sm={10}
            md={{ span: 8, offset: 2 }}
            className="container-bg-content"
          >
            <h2 className="page-title">Acesso</h2>
            <h4 className="page-subtitle">Digite o seu código de acesso</h4>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-group">
                <Controller
                  name="identifier"
                  control={control}
                  rules={{
                    validate: validate.cpfLength,
                  }}
                  render={({ field }) => (
                    <FormInput
                      input={field}
                      name="identifier"
                      label="CPF"
                      placeholder="CPF"
                      maxLength={14}
                      className="field-required form-control"
                      clearErrors={clearErrors}
                      meta={{
                        error: errors.identifier,
                        touched: false,
                        submitFailed: '',
                      }}
                      normalize={normalization.cpf}
                      disabled
                      setValue={setValue}
                    />
                  )}
                />
              </div>
              <div className="form-group">
                <Controller
                  name="code"
                  control={control}
                  rules={{ validate: validate.notEmpty }}
                  render={({ field }) => (
                    <FormInput
                      autoFocus={true}
                      input={field}
                      name="code"
                      placeholder="Código de Acesso"
                      maxLength={6}
                      className="field-required form-control"
                      meta={{
                        error: errors.code,
                        touched: false,
                        submitFailed: '',
                      }}
                      normalize={normalization.number}
                      setValue={setValue}
                      clearErrors={clearErrors}
                    />
                  )}
                />
              </div>
              <Button
                type="submit"
                className="btn btn-primary"
                disabled={fetching}
              >
                {fetching ? 'Fazendo Login...' : 'Comece a Usar'}
              </Button>
            </form>
            <br />
            <h5 className="margin-y-4">
              Não recebeu o código? <Link to="/login">Reenviar o SMS</Link>
            </h5>
          </Col>
        </div>
      </div>
      <ErrorOverlay
        visible={loginRejected}
        onClose={() => {
          setLoginRejected(false);
          if (loginError && loginError.status === 409) {
            history.push('login');
          }
        }}
        buttonTitle="Voltar"
        title="Erro"
        message={loginError ? loginError.message : ''}
      />
    </div>
  );
};

export default AccessToken;
