import React, { useEffect } from 'react';
import { Route, useRouteMatch } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setPageTitle } from '../../../actions';
import ScheduleList from './ScheduleList';
import ScheduleService from './ScheduleService';
import ScheduleDate from './ScheduleDate';
import ScheduleConfirmation from './ScheduleConfirmation';

const Schedule: React.FC = () => {
  const match = useRouteMatch();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setPageTitle('Agendamentos'));
  }, [dispatch]);

  return (
    <div>
      <Route exact path={`${match.url}/`} component={ScheduleList} />
      <Route path={`${match.url}/service`} component={ScheduleService} />
      <Route path={`${match.url}/date`} component={ScheduleDate} />
      <Route
        path={`${match.url}/confirmation`}
        component={ScheduleConfirmation}
      />
    </div>
  );
};

export default Schedule;
