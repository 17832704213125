import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import configureStore from './helper/redux-store';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import App from './containers';
import './styles/app.scss';
import { axiosAuth } from './helper/axios-auth';
import { AxiosInstance } from 'axios';

declare global {
  function axiosAuth(): AxiosInstance;
}

globalThis.axiosAuth = axiosAuth;

// TMP fix remove service worker:
navigator.serviceWorker.getRegistrations().then(function (registrations) {
  for (let registration of registrations) {
    registration.unregister();
  }
});

ReactDOM.render(
  <Provider store={configureStore()}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root'),
);
