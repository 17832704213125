import React from 'react';
import { useSelector } from 'react-redux';

const iconMap: { [key: string]: any } = {
  single: {
    'account-outline': 'icons/account.png',
    phone: 'icons/call.png',
    autorenew: 'icons/callme.png',
    'message-text-outline': 'icons/chat.png',
    'email-outline': 'icons/email.png',
    laptop: 'icons/video.png',
    whatsapp: 'icons/whatsapp.png',
  },
  double: {
    // ...
  },
};

export interface ImgIconProps {
  icon: string;
  fallback: Function;
}

const ImgIcon: React.FC<ImgIconProps> = ({ icon, fallback }) => {
  const { slug } = useSelector<any, any>((state) => state.labelReducer);

  const iconSet = slug === 'magalu' ? 'single' : 'double';
  const iconSrc = iconMap[iconSet][icon];

  if (!iconSrc) {
    return fallback();
  }

  return <img src={`/${slug}/${iconSrc}`} alt="" />;
};

// function mapStateToProps({ labelReducer }: any) {
//   const { slug } = labelReducer;
//   return { slug };
// }

export default ImgIcon;
