import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Image, Ratio } from 'react-bootstrap';
import { ContentContainer } from '../../../components';
import { fetchMedia } from 'cdfdigital-common/build/actions';
import appendQueryString from 'cdfdigital-common/build/helper/append-query-string';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { MediaItem, MediaState } from 'cdfdigital-common/src/models';

const parseYouTubeId = (url: string) => {
  const regex =
    /^.*(youtu\.be\/|vi?\/|u\/\w\/|embed\/|\?vi?=|&vi?=)([^#&?]*).*/;
  const matches = url.match(regex);
  return matches ? matches[2] : null;
};

const parseVideoUrl = (url: string) => {
  if (url.includes('youtube')) {
    const id = parseYouTubeId(url);
    if (id) {
      return (
        'https://www.youtube.com/embed/' +
        id +
        '?rel=0&controls=1&modestbranding=1'
      );
    }
  }
  return url;
};

const MediaItemComponent: React.FC = () => {
  const history = useHistory();

  const match = useRouteMatch<any>();

  const [mediaItem, setMediaItem] = React.useState<undefined | MediaItem>(
    undefined,
  );

  const { data: media } = useSelector<any, MediaState>(
    (state) => state.mediaReducer,
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (!media.length) {
      dispatch(fetchMedia());
    }
  }, [dispatch, media]);

  useEffect(() => {
    if (media.length) {
      setMediaItem(media.find((item) => item._id === match.params.id));
    }
  }, [media, match.params.id]);

  if (!mediaItem) {
    return (
      <ContentContainer id="container-newsItem">
        <Row>
          <Col xs={12}>Carregando...</Col>
        </Row>
      </ContentContainer>
    );
  }

  return (
    <ContentContainer
      id="container-newsItem"
      header={
        mediaItem.video ? null : (
          <div className="header">
            <Image
              className="image"
              fluid
              src={appendQueryString(
                mediaItem.image,
                'w=1500&h=500&fit=crop&blend=000000&bm=darken&balph=30',
              )}
            />
            <div className="news-info">
              <h2>{mediaItem.title}</h2>
              <h4 className="description">{mediaItem.description}</h4>
            </div>
          </div>
        )
      }
    >
      <Row className="no-gutters news">
        <Col xs={12}>
          {mediaItem.video ? (
            <div className="video mb-4">
              <Ratio aspectRatio="16x9">
                <embed src={parseVideoUrl(mediaItem.video)} />
              </Ratio>
            </div>
          ) : null}
        </Col>
      </Row>
      {mediaItem.video && (
        <div>
          <h3>{mediaItem.title}</h3>
          <p className="description">{mediaItem.description}</p>
        </div>
      )}
      <Row>
        <Col xs={12}>
          <p className="text">{mediaItem.text}</p>
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="back-btn btn-center">
          <button onClick={() => history.goBack()} className="btn btn-primary">
            Voltar
          </button>
        </Col>
      </Row>
    </ContentContainer>
  );
};

export default MediaItemComponent;
