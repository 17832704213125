import React, { useEffect } from 'react';
import { Modal, Button, Col, Row } from 'react-bootstrap';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { setPageTitle } from '../../../actions';
import {
  fetchServiceChannels,
  fetchUser,
} from 'cdfdigital-common/build/actions';
import {
  ErrorOverlay,
  Icon,
  ImgIcon,
  ContentContainer,
} from '../../../components';
import { useHistory } from 'react-router-dom';
import { ServiceChannelsState, UserState } from 'cdfdigital-common/src/models';

const ServiceChannels: React.FC = () => {
  const history = useHistory();

  const [showPhone, setShowPhone] = React.useState(false);

  const [showDisclaimer, setShowDisclaimer] = React.useState(null);

  const { slug: labelSlug } = useSelector<any, any>(
    (state) => state.labelReducer,
  );

  const { data: user } = useSelector<any, UserState>(
    (state) => state.userReducer,
  );

  const { data: services, error } = useSelector<any, ServiceChannelsState>(
    (state) => state.serviceChannelsReducer,
  );

  const serviceList = [
    {
      title: 'WhatsApp',
      route: 'WhatsApp',
      action: () => {
        const { NR_CPF } = user;
        const contact = getServiceContact('WhatsApp');
        const apiUrl = contact.match(/^https?:/)
          ? contact.replace('$CLIENTE_CPF', NR_CPF) // Full link registered on InterAdmin
          : `https://api.whatsapp.com/send?phone=${contact}&text=${_.capitalize(
              labelSlug,
            )}`; // Only phone registered on InterAdmin

        return window.open(apiUrl, '_blank');
      },
      icon: 'whatsapp',
    },
    {
      title: 'Chat',
      route: 'Chat',
      action: () => {
        history.push(`/main/service-channels/chat`, {
          src: getServiceContact('Chat'),
        });
      },
      icon: 'message-text-outline',
    },
    {
      title: 'Ligue-me',
      route: 'CallMe',
      action: () => {
        history.push('/main/service-channels/call-me');
      },
      icon: 'autorenew',
    },
    {
      title: 'Vídeo',
      route: 'VideoCall',
      action: () => {
        history.push('/main/service-channels/video-call');
      },
      icon: 'laptop',
    },
    {
      title: 'Ligar',
      route: 'Call',
      action: () => {
        openTel();
      },
      icon: 'phone',
    },
    {
      title: 'Email',
      route: 'Email',
      action: () => {
        history.push('/main/service-channels/email');
      },
      icon: 'email-outline',
    },
  ];

  const getServiceContact = (service: any) =>
    services.find((item) => item.type === service)!.contact;

  const openTel = () => {
    if (!showPhone) {
      setShowPhone(true);
    } else {
      window.open(`tel:${getServiceContact('Call')}`);
    }
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchUser());
    dispatch(setPageTitle('Atendimento'));
    dispatch(fetchServiceChannels());
  }, [dispatch]);

  const retry = () => {
    fetchServiceChannels();
  };

  const renderDisclaimer = (item: any) =>
    item &&
    item.disclaimer && (
      <Modal show={showDisclaimer !== null} onHide={() => {}}>
        <Modal.Header>
          <Modal.Title>Aviso</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>{item.disclaimer}</h4>
        </Modal.Body>
        <Modal.Footer>
          <Button
            bsPrefix="btn btn-primary"
            onClick={() => {
              setShowDisclaimer(null);
              item.action(labelSlug);
            }}
          >
            Prosseguir
          </Button>
          <Button
            bsPrefix="btn btn-gray"
            onClick={() => {
              setShowDisclaimer(null);
            }}
          >
            Cancelar
          </Button>
        </Modal.Footer>
      </Modal>
    );

  const renderCards = () => {
    return _.chunk(
      serviceList
        .filter((item: any) => {
          return services.map((item: any) => item.type).includes(item.route);
        })
        .map((item: any) => {
          const { disclaimer, order } = services.find(
            (service) => service.type === item.route,
          )!;
          return {
            ...item,
            disclaimer,
            order,
          };
        }),
    ).map((row, rowKey) => (
      <Col xs={4} sm={4} className="card-row" key={rowKey}>
        {row.map((item, key) => (
          <div
            key={key}
            className={`card-item ${(rowKey + key) % 2 ? 'even' : 'odd'}`}
            onClick={() => {
              if (item.disclaimer) {
                setShowDisclaimer(item);
              } else {
                item.action();
              }
            }}
          >
            <div className="item-content">
              <ImgIcon
                icon={item.icon}
                fallback={() => <Icon name={item.icon} />}
              />
              {item.route === 'Call' && showPhone ? (
                <h3>Tel: {getServiceContact('Call')}</h3>
              ) : (
                <h3>{item.title}</h3>
              )}
            </div>
          </div>
        ))}
      </Col>
    ));
  };

  return (
    <ContentContainer id="container-serviceChannels" header="Atendimento">
      <Row xs={12} sm={12} className="card-list">
        {renderCards()}
      </Row>
      <ErrorOverlay
        visible={error !== null}
        onClose={retry}
        title="Erro"
        buttonTitle="Tentar novamente"
        message="Ocorreu um erro. Verifique a sua conexão e tente novamente."
      />
      {renderDisclaimer(showDisclaimer)}
    </ContentContainer>
  );
};

export default ServiceChannels;
