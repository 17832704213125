import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { Button, Row, Col } from 'react-bootstrap';
import { FormInput, ErrorOverlay } from '../../../components';
import {
  setUserAddress,
  /*fetchCep as asyncValidate, */
} from 'cdfdigital-common/build/actions';
import { scheduleActions } from 'cdfdigital-common/build/reducers/scheduleReducer';
import uf from 'cdfdigital-common/build/helper/uf-list';
import normalization from 'cdfdigital-common/build/helper/normalization';
import validate from 'cdfdigital-common/build/helper/validation';
import { ContentContainer, HeaderIcon } from '../../../components';
import { useHistory } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import {
  CepState,
  UserAddressState,
  UserState,
} from 'cdfdigital-common/src/models';

const ScheduleService: React.FC = () => {
  const history = useHistory();

  //const [service, setService] = useState<string | null>(null);

  const [errorOverlay, setErrorOverlay] = useState(false);

  const { data: user } = useSelector<any, UserState>(
    (state) => state.userReducer,
  );

  const {
    data: address,
    fetching: addressSaving,
    error: addressError,
  } = useSelector<any, UserAddressState>((state) => state.userReducer);

  const {
    data: cepAddress,
    // error: cepError,
    // fetching: cepFetceching,
  } = useSelector<any, CepState>((state) => state.cepReducer);

  let contracts: any[] = [];

  if (user) {
    contracts = user.CONTRATO.filter(
      (item: any) => item.SERVICOS.QT_VISITAS,
    ).map((item: any) => ({
      label: item.SERVICOS.DESCRICAO,
      value: item.ID_CLIENTE_CONTRATO,
    }));
  }

  const {
    CD_TIPO_ENDERECO,
    DS_LOGRADOURO,
    NR_LOGRADOURO,
    NR_CEP,
    DS_BAIRRO,
    CD_UF,
    DS_CIDADE,
    DS_COMPLEMENTO,
  } = address || {};

  const dispatch = useDispatch();

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    clearErrors,
  } = useForm<any>({
    defaultValues: {
      CD_TIPO_ENDERECO,
      DS_LOGRADOURO,
      NR_LOGRADOURO,
      NR_CEP,
      DS_BAIRRO,
      CD_UF,
      DS_CIDADE,
      DS_COMPLEMENTO,
    },
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const onSubmit = ({ service, ...address }: any) => {
    let serviceItem = contracts.find((item: any) => item.value === service);

    // @ts-ignore
    dispatch(setUserAddress(address)).then(() => {
      if (!addressError) {
        dispatch(scheduleActions.setService(serviceItem));
        history.push('date');
      }
    });
  };

  useEffect(() => {
    if (cepAddress && NR_CEP !== cepAddress.NR_CEP) {
      _.forEach(cepAddress, (value, key) => {
        if (key !== 'NR_CEP') {
          setValue(key.replace('ENDERECO', 'LOGRADOURO'), value);
        }
      });
      setValue('NR_LOGRADOURO', '');
      setValue('DS_COMPLEMENTO', '');
    }
    if (addressError) {
      setErrorOverlay(true);
    }
  }, [cepAddress, addressError, NR_CEP, setValue]);

  useEffect(() => {
    if (!user) {
      history.replace('/main/schedule');
    }
  }, [history, user]);

  const dismiss = () => {
    setErrorOverlay(false);
  };

  return (
    <ContentContainer id="container-scheduleService">
      <div className="icon-container">
        <HeaderIcon name={'laptop'} />
      </div>
      <Row>
        <Col xs={12}>
          <h2 className="page-title">Agendamento</h2>
        </Col>
      </Row>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-group">
          <select
            name="service"
            //label="Serviço"
            className="field-required form-control"
            //componentClass="select"
          >
            {contracts.map(({ label, value }: any, key: any) => (
              <option key={key} value={value}>
                {label}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <Controller
            name="NR_CEP"
            control={control}
            rules={{
              validate: validate.cepLength,
            }}
            render={({ field }) => (
              <FormInput
                input={field}
                name="NR_CEP"
                // label='CEP'
                placeholder="CEP  "
                //maxLength={9}
                className="field-required form-control"
                normalize={normalization.cep}
                clearErrors={clearErrors}
                meta={{
                  error: errors.NR_CEP,
                  touched: false,
                  submitFailed: '',
                }}
              />
            )}
          />
        </div>
        <div className="form-group">
          <select
            name="CD_UF"
            //label="Estado"
            className="field-required form-control"
            //componentClass="select"
          >
            {uf.map(({ label, value }, key) => (
              <option key={key} value={value}>
                {label}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <Controller
            name="DS_CIDADE"
            control={control}
            rules={{
              validate: validate.notEmpty,
            }}
            render={({ field }) => (
              <FormInput
                input={field}
                name="DS_CIDADE"
                // label='Cidade'
                placeholder="Cidade"
                className="field-required form-control"
                clearErrors={clearErrors}
                meta={{
                  error: errors.DS_CIDADE,
                  touched: false,
                  submitFailed: '',
                }}
              />
            )}
          />
        </div>
        <div className="form-group">
          <Controller
            name="DS_LOGRADOURO"
            control={control}
            rules={{
              validate: validate.notEmpty,
            }}
            render={({ field }) => (
              <FormInput
                input={field}
                name="DS_LOGRADOURO"
                // label='Logradouro'
                placeholder="Logradouro"
                //maxLength={9}
                className="field-required form-control"
                clearErrors={clearErrors}
                meta={{
                  error: errors.DS_LOGRADOURO,
                  touched: false,
                  submitFailed: '',
                }}
              />
            )}
          />
        </div>
        <div className="form-group">
          <Controller
            name="NR_LOGRADOURO"
            control={control}
            rules={{
              validate: validate.notEmpty,
            }}
            render={({ field }) => (
              <FormInput
                input={field}
                name="NR_LOGRADOURO"
                // label='Número'
                placeholder="Número"
                className="field-required form-control"
                normalize={normalization.number}
                clearErrors={clearErrors}
                meta={{
                  error: errors.NR_LOGRADOURO,
                  touched: false,
                  submitFailed: '',
                }}
              />
            )}
          />
        </div>
        <div className="form-group">
          <Controller
            name="DS_COMPLEMENTO"
            control={control}
            rules={{
              validate: validate.email,
            }}
            render={({ field }) => (
              <FormInput
                input={field}
                name="DS_COMPLEMENTO"
                // label='Complemento'
                placeholder="Complemento"
                className="field-required form-control"
                clearErrors={clearErrors}
                meta={{
                  error: errors.DS_COMPLEMENTO,
                  touched: false,
                  submitFailed: '',
                }}
              />
            )}
          />
        </div>
        <Row>
          <Col xs={12} className="btn-center">
            <Button type="submit" className="btn btn-primary">
              {addressSaving ? 'Enviando...' : 'Continuar'}
            </Button>
          </Col>
        </Row>
      </form>
      <ErrorOverlay
        visible={errorOverlay}
        onClose={dismiss}
        title="Erro"
        buttonTitle="Voltar"
        message="Ocorreu um erro. Verifique a sua conexão e tente novamente."
      />
    </ContentContainer>
  );
};

export default ScheduleService;
