import React from 'react';
import Media from './Media';
import MediaItem from './MediaItem';
import { Route, useRouteMatch } from 'react-router-dom';

const NewsRoutes: React.FC = () => {
  const match = useRouteMatch();

  return (
    <div>
      <Route exact path={`${match.url}/`} component={Media} />
      <Route path={`${match.url}/:id`} component={MediaItem} />
    </div>
  );
};

export default NewsRoutes;
