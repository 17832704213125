import { createStore, applyMiddleware } from 'redux';
import ReduxThunk from 'redux-thunk';
import reducers from '../reducers';

const configureStore = () => {
  const middlewares = [ReduxThunk];
  return createStore(reducers, {}, applyMiddleware(...middlewares));
};

export default configureStore;
