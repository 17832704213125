import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Button, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { getAccessToken, setPageTitle, logIn } from '../actions';
import { FormInput, ErrorOverlay, Header, HeaderIcon } from '../components';
import normalization from 'cdfdigital-common/build/helper/normalization';
import validate from 'cdfdigital-common/build/helper/validation';
import detectCurrentLabel from '../helper/label';
import { AccessTokenState, AuthInfoState } from 'cdfdigital-common/src/models';
import { authInfoActions } from '../reducers/authInfoReducer';
import { accessTokenActions } from '../reducers/accessTokenReducer';

const Login: React.FC = () => {
  const history = useHistory();

  const dispatch = useDispatch();

  const [tokenError, setTokenError] = useState(false);

  const [hasSubmitted, setHasSubmitted] = useState(false);

  const { data: authInfo } = useSelector<any, AuthInfoState>(
    (state) => state.authInfoReducer,
  );

  const accessToken = useSelector<any, AccessTokenState>(
    (state) => state.accessTokenReducer,
  );

  const initialValues = {
    identifier: normalization.cpf(authInfo.identifier),
    phone: normalization.tel(authInfo.phone),
  };

  //const previousPincode = authInfo.pincode; // previously selected option

  // useEffect(() => {
  //   if (initialValues.identifier) {
  //     setState({ ...state, pincode: previousPincode });
  //   }
  // }, [state, initialValues, previousPincode]);

  useEffect(() => {
    // Clear state on page load to avoid using same returned data after logoff
    dispatch(accessTokenActions.clearState());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(setPageTitle('Criar Código de Acesso'));
    const token = localStorage.getItem('token');
    if (token) {
      dispatch(logIn({ token }));
      history.push('main'); // FIXME test code
    }
  }, [dispatch, history]);

  useEffect(() => {
    if (accessToken.error) {
      setTokenError(true);
    }
  }, [accessToken]);

  useEffect(() => {
    if (hasSubmitted && !accessToken.error && accessToken.data?.signature) {
      history.push('access-token');
    }
  }, [accessToken, hasSubmitted, history]);

  const onSubmit = (values: any) => {
    setHasSubmitted(true);

    const { identifier, phone } = values;

    const state: AuthInfoState = {
      data: {
        identifier: identifier.replace(/\D/g, ''),
        phone: phone.replace(/\D/g, ''),
      },
    };

    dispatch(authInfoActions.setData(state));
    dispatch(getAccessToken(state));
  };

  const getErrorMessage = () => {
    if (accessToken.error) {
      return accessToken.error.message;
    }
    return '';
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    clearErrors,
  } = useForm<any>({
    defaultValues: initialValues,
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  return (
    <div id="container-access-token">
      <Header /*title='Criar Código de Acesso'*/ />
      <div className="container-bg-fluid">
        <div className="container-bg-left">
          <div className="container-bg-menu">
            <div className="icon-container">
              <HeaderIcon name={'account-outline'} />
            </div>
          </div>
        </div>
        <div className="container-bg-right">
          <Row className="d-flex">
            <Col
              xs={12}
              sm={10}
              md={{ span: 8, offset: 2 }}
              className="container-bg-content"
            >
              <form onSubmit={handleSubmit(onSubmit)}>
                <h2 className="page-title">Acesso</h2>
                <h4 className="page-subtitle">
                  {detectCurrentLabel() === 'pernambucanas'
                    ? 'O ACESSO POR CPF PODE SER FEITO A PARTIR DO DIA SEGUINTE À COMPRA DO PRODUTO.'
                    : 'DIGITE O CPF DO TITULAR DA COMPRA DO SERVIÇO PARA INICIAR'}
                </h4>

                <div className="form-group">
                  <Controller
                    name="identifier"
                    control={control}
                    rules={{
                      validate: validate.cpfLength,
                    }}
                    render={({ field }) => (
                      <FormInput
                        input={field}
                        name="identifier"
                        placeholder="CPF"
                        maxLength={14}
                        className="field-required form-control"
                        meta={{
                          error: errors.identifier,
                          touched: false,
                          submitFailed: '',
                        }}
                        normalize={normalization.cpf}
                        clearErrors={clearErrors}
                        setValue={setValue}
                      />
                    )}
                  />
                </div>
                <div className="form-group">
                  <Controller
                    name="phone"
                    control={control}
                    rules={{ validate: validate.telLength }}
                    render={({ field }) => (
                      <FormInput
                        input={field}
                        name="phone"
                        placeholder={
                          detectCurrentLabel() === 'tokiomarine'
                            ? 'Celular'
                            : 'Telefone'
                        }
                        className="field-required form-control"
                        meta={{
                          error: errors.phone,
                          touched: false,
                          submitFailed: '',
                        }}
                        maxLength={15}
                        normalize={normalization.tel}
                        clearErrors={clearErrors}
                        setValue={setValue}
                      />
                    )}
                  />
                </div>

                <Button
                  type="submit"
                  className="btn btn-primary"
                  disabled={accessToken.fetching}
                >
                  {accessToken.fetching
                    ? 'Enviando Código de Acesso...'
                    : 'Enviar Código'}
                </Button>
              </form>
            </Col>
            {(detectCurrentLabel() === 'ticket' ||
              detectCurrentLabel() === 'tokiomarine') && (
              <Col
                xs={12}
                sm={10}
                md={{ span: 8, offset: 2 }}
                className="container-bg-content mt-link"
              >
                <h6>
                  Confira a nossa{' '}
                  <a
                    href="https://politica-de-privacidade.cdf.net/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Política de Privacidade
                  </a>
                  .
                </h6>
              </Col>
            )}
          </Row>
        </div>
      </div>
      <ErrorOverlay
        visible={tokenError}
        onClose={() => setTokenError(false)}
        buttonTitle="Tentar Novamente"
        title="Erro"
        message={getErrorMessage()}
      />
    </div>
  );
};

export default Login;
