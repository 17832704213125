import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import GoogleMapReact from 'google-map-react';
import { Row, Col } from 'react-bootstrap';
import { fetchStoreLocation } from 'cdfdigital-common/build/actions';
import { HeaderIcon } from '../../../components';
import {
  StoreLocationItem,
  StoreLocationState,
} from 'cdfdigital-common/src/models';
import Marker from './Marker';
import ActiveMarker from './ActiveMarker';

export interface Coordinates {
  latitude: number;
  longitude: number;
}

const StoreLocation: React.FC = () => {
  const [showInfoBox, setShowInfoBox] = useState(true);

  const [userLocation, setUserLocation] = useState<Coordinates | null>(null);

  const [activeMarker, setActiveMarker] = useState<StoreLocationItem | null>(
    null,
  );

  const [error, setError] = useState<GeolocationPositionError | null>(null);

  const {
    fetching,
    error: storeLocationError,
    data: { initialRegion, items: stores },
  } = useSelector<any, StoreLocationState>(
    (state) => state.storeLocationReducer,
  );
  const { slug } = useSelector<any, any>((state) => state.labelReducer);

  const dispatch = useDispatch();

  const getUserPosition = useCallback(() => {
    navigator.geolocation.getCurrentPosition(
      ({ coords: { latitude, longitude } }) => {
        setUserLocation({
          latitude,
          longitude,
        });
        dispatch(fetchStoreLocation({ latitude, longitude }));
      },
      (error) => {
        console.log({ error });
        setError(error);
      },
    );
  }, [dispatch]);

  useEffect(() => {
    getUserPosition();
  }, [getUserPosition]);

  const onMapCreated = ({ map, maps }: any) => {
    var bounds = new maps.LatLngBounds();
    stores.forEach((marker: StoreLocationItem) => {
      bounds.extend({
        lat: marker.location.coordinates[1],
        lng: marker.location.coordinates[0],
      });
    });
    map.fitBounds(bounds);
  };

  if (fetching) {
    return <div>Carregando...</div>;
  }

  if (storeLocationError) {
    return <div>Erro</div>;
  }

  return (
    <div id="container-storeLocation" className="container-bg-right">
      <div className="container-bg-content">
        <div className={`header${showInfoBox ? '' : ' dismissed'}`}>
          <div className="icon-container">
            <HeaderIcon name={'map-marker'} />
          </div>
          <Row>
            <Col xs={12}>
              <h2 className="page-title">Encontre a loja mais próxima</h2>
              <h4 className="page-subtitle">
                Confira abaixo as lojas mais próximas a você
              </h4>
              {error ? (
                <h4 className="page-subtitle">Geolocalização bloqueada</h4>
              ) : (
                ''
              )}
            </Col>
          </Row>
        </div>
      </div>

      <GoogleMapReact
        style={{ width: '100%', height: '100%' }}
        defaultCenter={{
          lat: initialRegion ? initialRegion.latitude : -23.533773,
          lng: initialRegion ? initialRegion.longitude : -46.62529,
        }}
        zoom={initialRegion?.latitude ? 12 : 12}
        bootstrapURLKeys={{
          key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '',
        }}
        //showsUserLocation
        onDrag={() => {
          if (showInfoBox) {
            setShowInfoBox(false);
          }
        }}
        onGoogleApiLoaded={onMapCreated}
      >
        {userLocation && (
          <Marker
            lat={userLocation.latitude}
            lng={userLocation.longitude}
            icon={'../current-location.png'}
          />
        )}
        {stores.map((marker) => (
          <Marker
            key={marker._id}
            lat={marker.location.coordinates[1]}
            lng={marker.location.coordinates[0]}
            onClick={() => setActiveMarker(marker)}
            icon={`../${slug}/map_pin.png`}
          />
        ))}
        {activeMarker && (
          <ActiveMarker
            marker={activeMarker}
            lat={activeMarker.location.coordinates[1]}
            lng={activeMarker.location.coordinates[0]}
            closeAction={() => setActiveMarker(null)}
            icon={`../${slug}/map_pin.png`}
          />
        )}
      </GoogleMapReact>
    </div>
  );
};

export default StoreLocation;
