import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import detectCurrentLabel from '../../helper/label';
import {
  Container,
  Row,
  Col,
  Button,
  ProgressBar,
  Modal,
} from 'react-bootstrap';
import { BiExit } from 'react-icons/bi';
import moment from 'moment';
import { setPageTitle } from '../../actions';
import {
  fetchUser,
  logOut,
  //logoutUser,
  fetchTermsOfService,
} from 'cdfdigital-common/build/actions';
import normalization from 'cdfdigital-common/build/helper/normalization';
import { ErrorOverlay, ContentContainer, HeaderIcon } from '../../components';
import { useHistory } from 'react-router-dom';
import { TermsOfServiceState, UserState } from 'cdfdigital-common/src/models';

const Account: React.FC<any> = () => {
  const history = useHistory();

  const [showInfo, setShowInfo] = useState(false);

  const {
    data: user,
    //fetching: fetchingUser,
    error: errorUser,
  } = useSelector<any, UserState>((state) => state.userReducer);

  const {
    data,
    //fetching: fetchingTermsOfService,
    error: errorTermsOfService,
  } = useSelector<any, TermsOfServiceState>(
    (state) => state.termsOfServiceReducer,
  );

  const { ID_CLIENTE_CORPORATIVO } = useSelector<any, any>(
    (state) => state.labelReducer,
  );

  const error = errorUser || errorTermsOfService;
  //const fetching = fetchingUser || fetchingTermsOfService;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setPageTitle('Conta'));
    dispatch(fetchTermsOfService());
  }, [dispatch]);

  const renderAddress = () => {
    const { LISTA_ENDERECO } = user;
    return LISTA_ENDERECO.map((address: any, key: any) => (
      <div key={key} className="card">
        <div className="card-content">
          <h5>
            {address.DS_CIDADE} - {address.CD_UF}
          </h5>
          <h6>
            {address.DS_LOGRADOURO}, {address.NR_LOGRADOURO} -{' '}
            {address.DS_BAIRRO}
          </h6>
          <h6 className="mb-0">CEP: {address.NR_CEP}</h6>
        </div>
      </div>
    ));
  };

  const renderTelephones = () => {
    const { LISTA_TELEFONE } = user;
    return LISTA_TELEFONE.map((tel: any, key: any) => (
      <div key={key} className="card">
        <div className="card-content">
          <h5 className="mb-0">
            {normalization.tel(tel.NR_DDD + tel.NR_FONE)}
          </h5>
        </div>
      </div>
    ));
  };

  const renderServiceAvailability = () => {
    if (!user.CONTRATO || !user.CONTRATO.length) {
      return null;
    }

    let contractStart = moment(user.CONTRATO[0].DT_INI_VIGENCIA, 'DD/MM/YYYY');
    let contractEnd = moment(user.CONTRATO[0].DT_FIM_VIGENCIA, 'DD/MM/YYYY');

    return (
      <Row className="mb-2">
        <Col xs={12}>
          <h3 className="title">
            Serviço Válido até: {user.CONTRATO[0].DT_FIM_VIGENCIA}
          </h3>
        </Col>
        <Col xs={12}>
          <div className="mb-2">
            <ProgressBar
              now={
                (moment().diff(contractStart, 'days') /
                  contractEnd.diff(contractStart, 'days')) *
                100
              }
            />
          </div>
        </Col>
        <Col className="mb-2" xs={12}>
          <div className="float-start">{user.CONTRATO[0].DT_INI_VIGENCIA}</div>
          <div className="float-end">{user.CONTRATO[0].DT_FIM_VIGENCIA}</div>
        </Col>
        <Col xs={12} className="terms-of-service-row">
          <Button bsPrefix="btn btn-primary" onClick={() => setShowInfo(true)}>
            Ver Descritivo Completo
          </Button>
        </Col>
        <Modal show={showInfo} onHide={() => {}} size="lg">
          <Modal.Header>
            <Modal.Title>
              {detectCurrentLabel() === 'magalu'
                ? 'Magalu Conecta'
                : 'Descritivo de Serviços'}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="white-space-pre">
            {data.termsOfService}
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={() => {
                setShowInfo(false);
              }}
            >
              Fechar
            </Button>
          </Modal.Footer>
        </Modal>
      </Row>
    );
  };

  const handleLogout = () => {
    dispatch(logOut());
    //dispatch(logoutUser());
    dispatch(setPageTitle(''));
    history.replace('/');
  };

  const retry = () => {
    fetchUser();
    fetchTermsOfService();
  };

  if (!user) {
    return (
      <Container>
        <h3>Carregando...</h3>
        <ErrorOverlay
          visible={error !== null}
          onClose={retry}
          buttonTitle="Tentar Novamente"
          title="Erro"
          message="Ocorreu um erro. Verifique a sua conexão e tente novamente."
        />
      </Container>
    );
  }

  return (
    <ContentContainer id="container-account">
      <Row>
        <Col xs={6}>
          <HeaderIcon name="account-outline" />
        </Col>
        <Col xs={6} sm={12}>
          <h2 className="page-title">SUA CONTA</h2>
        </Col>
      </Row>
      <Row className="user-row">
        <Col xs={7} sm={6}>
          <h3 className="title">{user.NM_CLIENTE}</h3>
        </Col>
        <Col xs={5} sm={6}>
          <Button bsPrefix="btn btn-gray float-end p-2" onClick={handleLogout}>
            Sair <BiExit />
          </Button>
        </Col>
      </Row>
      <br />
      {renderServiceAvailability()}
      <Row>
        <Col xs={12}>
          <h4>Endereços:</h4>
        </Col>
      </Row>
      {renderAddress()}
      <Row>
        <Col xs={12}>
          <h4>Telefones:</h4>
        </Col>
      </Row>
      {renderTelephones()}
      <Row>
        <Col xs={12}>
          <div style={{ marginTop: '32px', color: '#ccc' }}>
            ID_CLIENTE_CORPORATIVO: {ID_CLIENTE_CORPORATIVO} / NO CONTRATO:{' '}
            {user.CONTRATO[0].ID_CLIENTE_CORPORATIVO}
          </div>
        </Col>
      </Row>
    </ContentContainer>
  );
};

export default Account;
