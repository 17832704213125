import React from 'react';
import { Col } from 'react-bootstrap';

export interface ContentContainerProps extends React.HTMLProps<HTMLDivElement> {
  className?: string;
  header?: any;
}

const ContentContainer: React.FC<ContentContainerProps> = ({
  children,
  className,
  header = null,
  ...props
}) => {
  return (
    <div
      {...props}
      className={`container-bg-right ${className ? className : ''}`}
    >
      {header ? (
        <Col xs={12}>
          <div className="container-header">{header}</div>
        </Col>
      ) : (
        ''
      )}
      <Col xs={12} className="container-bg-content">
        {children}
      </Col>
    </div>
  );
};

export default ContentContainer;
