import { SET_LABEL_INFO } from '../actions/types';

const INITIAL_STATE = {
  name: '',
  slug: '',
  ID_CLIENTE_CORPORATIVO: 0,
};

export const labelReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case SET_LABEL_INFO:
      return {
        ...action.payload,
      };
    default:
      return state;
  }
};
