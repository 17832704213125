const detectCurrentLabel = (hostname?: string) => {
  const blacklist = ['localhost', 'www', 'site', 'proxy'];
  hostname = hostname || window.location.hostname;

  if (hostname === 'www.magaluconecta.com.br') {
    return 'magalu';
  }
  if (hostname === 'www.vidasaudavel-tokiomarine.com.br') {
    return 'tokiomarine';
  }
  if (hostname === 'coachsaude.cdf.net') {
    return 'ticket';
  }
  const alias = hostname.split('.')[0];
  if (!alias.match(/^[a-z]/) || blacklist.includes(alias)) {
    return 'cdf'; // FIXME extra pernambucanas magalu pontofrio casasbahia
  }
  return alias;
};

export default detectCurrentLabel;
