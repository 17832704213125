import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Container, Row, Col, Button, Modal } from 'react-bootstrap';
import { createSchedule, editSchedule } from 'cdfdigital-common/build/actions';
import { HeaderIcon, ContentContainer } from '../../../components';
import { useHistory, useLocation } from 'react-router-dom';

const ScheduleConfirmation: React.FC = () => {
  const [sending, setSending] = useState(false);

  const location = useLocation<any>();

  const history = useHistory();

  const { address } = useSelector<any, any>((state) => state.userReducer);
  const { service, date, time, fetching, createError } = useSelector<any, any>(
    (state) => state.scheduleReducer,
  );

  useEffect(() => {
    if (location.state && location.state.edit && !date) {
      history.replace('/main/schedule');
    } else if (
      (!location.state || !location.state.edit) &&
      (!address || !service || !date)
    ) {
      history.replace('/main/schedule');
    }
  }, [address, date, history, location.state, service]);

  const confirmSchedule = () => {
    const transposedDate = moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY');
    if (location.state && location.state.edit) {
      editSchedule({
        id: location.state.ID_VISITA,
        DT_VISITA: transposedDate,
        ID_PERIODO: time.ID_VISITA_PERIODO,
      });
    } else {
      createSchedule({
        service: service.value,
        address,
        date: transposedDate,
        time: time.ID_VISITA_PERIODO,
      });
    }
    setSending(true);
  };

  const back = () => {
    history.replace('/main/schedule');
  };

  const dismiss = () => {
    setSending(false);
  };

  if (!address) {
    return (
      <Container>
        <h3>Carregando...</h3>
      </Container>
    );
  }
  const {
    DS_LOGRADOURO,
    NR_LOGRADOURO,
    NR_CEP,
    DS_CIDADE,
    CD_UF,
    DS_BAIRRO,
    DS_COMPLEMENTO,
  } = address;
  return (
    <ContentContainer id="container-scheduleConfirmation">
      <div className="icon-container">
        <HeaderIcon name={'laptop'} />
      </div>
      <Row>
        <Col xs={12}>
          <h2 className="page-title">Agendamento</h2>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <h4>
            {location.state && location.state.edit
              ? 'AGENDAMENTO REMARCADO PARA'
              : 'CONFIRME AS INFORMAÇÕES DO AGENDAMENTO:'}
          </h4>
        </Col>
      </Row>
      {(!location.state || !location.state.edit) && (
        <div>
          <Row>
            <Col xs={12}>
              <h5>{service.label.toUpperCase()}</h5>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <h4>ENDEREÇO</h4>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <h5>CEP: {NR_CEP}</h5>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <h5>
                {DS_CIDADE}, {CD_UF}
              </h5>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <h5>
                {DS_BAIRRO} - {DS_LOGRADOURO}, {NR_LOGRADOURO}{' '}
                {DS_COMPLEMENTO ? `, ${DS_COMPLEMENTO}` : ''}
              </h5>
            </Col>
          </Row>
        </div>
      )}
      <Row>
        <Col xs={12}>
          <h5>
            {moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY')} - {time.DESCRICAO}
          </h5>
        </Col>
      </Row>
      <Row>
        <Col className="btn-center" xs={12}>
          <Button
            className="btn-primary margin-y-2"
            onClick={confirmSchedule}
            disabled={sending}
          >
            {sending ? 'Enviando...' : 'Continuar'}
          </Button>
        </Col>
      </Row>
      <Modal show={sending && !fetching} onHide={() => {}}>
        <Modal.Header>
          <Modal.Title>{!createError ? 'Confirmação' : 'Erro'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>
            {!createError
              ? 'Seu agendamento foi realizado!'
              : 'Houve um erro ao realizar seu agendamento, tente novamente mais tarde.'}
          </h4>
        </Modal.Body>
        <Modal.Footer>
          <Button
            bsPrefix="btn btn-primary"
            onClick={createError === null ? back : dismiss}
          >
            Confirmar
          </Button>
        </Modal.Footer>
      </Modal>
    </ContentContainer>
  );
};

export default ScheduleConfirmation;
