import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import detectCurrentLabel from '../helper/label';
import { setLabelInfo, setPageTitle } from '../actions';
import Introduction from './Introduction';
import Login from './Login';
import AccessToken from './AccessToken';
import Main from './Main';
import VideoCallSupport from './Main/ServiceChannels/VideoCall/VideoCallSupport';
import { Route } from 'react-router-dom';

const App: React.FC = () => {
  const dispatch = useDispatch();

  const labelState = useSelector<any, any>((state) => state.labelReducer);

  useEffect(() => {
    dispatch(setLabelInfo(detectCurrentLabel()));
  }, [dispatch]);

  useEffect(() => {
    if (labelState.slug) {
      dispatch(setPageTitle(labelState.slug));
    }
  }, [dispatch, labelState]);

  if (!labelState.slug) {
    return <div>No label defined</div>;
  }

  return (
    <div id="app">
      <Route exact path="/" component={Introduction} />
      <Route exact path="/login" component={Login} />
      <Route exact path="/access-token" component={AccessToken} />
      <Route path="/main" component={Main} />
      <Route path="/support" component={VideoCallSupport} />
    </div>
  );
};

export default App;
