import { PAGE_SET_TITLE } from './types';
import pageTitle from '../helper/document-title';

export function setPageTitle(title: string) {
  return async (dispatch: any, getState: any) => {
    pageTitle(title, getState().labelReducer);
    return {
      type: PAGE_SET_TITLE,
      payload: title,
    };
  };
}
