import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AuthInfoState } from 'cdfdigital-common/src/models';

const initialState: AuthInfoState = {
  data: {
    identifier: process.env.REACT_APP_DEBUG_LOGIN_CPF || '',
    phone: process.env.REACT_APP_DEBUG_LOGIN_PHONE || '',
  },
};

const slice = createSlice({
  name: 'auth-info',
  initialState,
  reducers: {
    setData: (state, action: PayloadAction<AuthInfoState>) => {
      state.data = action.payload.data;
    },
  },
});

export const authInfoReducer = slice.reducer;
export const authInfoActions = slice.actions;
