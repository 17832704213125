import { SET_LABEL_INFO } from './types';

const labels = [
  {
    ID_CLIENTE_CORPORATIVO: 141,
    slug: 'magalu',
    name: 'Magalu Conecta',
  },
  {
    ID_CLIENTE_CORPORATIVO: 32,
    slug: 'cdf',
    name: 'CDF Digital',
  },
  {
    ID_CLIENTE_CORPORATIVO: 32,
    slug: 'casasbahia',
    name: 'Casas Bahia',
  },
  {
    ID_CLIENTE_CORPORATIVO: 158,
    slug: 'extra',
    name: 'Extra',
  },
  {
    ID_CLIENTE_CORPORATIVO: 38,
    slug: 'pontofrio',
    name: 'Pontofrio',
  },
  {
    ID_CLIENTE_CORPORATIVO: 152,
    slug: 'pernambucanas',
    name: 'Pernambucanas',
  },
  {
    ID_CLIENTE_CORPORATIVO: 175,
    slug: 'tokiomarine',
    name: 'Tokio Marine',
  },
  {
    ID_CLIENTE_CORPORATIVO: 541,
    slug: 'ticket',
    name: 'Ticket',
  },
];

export function setLabelInfo(label: string) {
  document.body.className = label;
  return {
    type: SET_LABEL_INFO,
    payload: labels.find((item) => item.slug === label),
  };
}
