import React, { useEffect } from 'react';
import {
  Route,
  Redirect,
  Switch,
  useHistory,
  useRouteMatch,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { default as ServiceChannels } from './ServiceChannels';
import { default as News } from './News';
import { default as Media } from './Media';
import { default as Tools } from './Tools';
import { default as Account } from './Account';
import { default as Schedule } from './Schedule';
import { default as StoreLocation } from './StoreLocation';
import { default as Home } from './Home';
import { Header, Menu, Footer, ErrorOverlay } from '../../components';
import { fetchUser, fetchTabs } from 'cdfdigital-common/build/actions';
import { routeMap } from '../../helper/routes';
import { TabsState } from 'cdfdigital-common/src/models';

const routeComponents: any = {
  News,
  ServiceChannels,
  Schedule,
  Tools,
  StoreLocation,
  Account,
  Media,
};

const previousTabs = JSON.parse(localStorage.getItem('tabs')!) || []; // avoid redirect on reload

const Main: React.FC<any> = () => {
  const history = useHistory();
  const match = useRouteMatch();

  const { title } = useSelector<any, any>((state) => state.pageReducer);

  const { data: tabs, error } = useSelector<any, TabsState>(
    (state) => state.tabsReducer,
  );

  // const { user, error: userError } = useSelector<any, any>(
  //   (state) => state.userReducer,
  // );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchTabs());
    dispatch(fetchUser());
  }, [dispatch]);

  // UNSAFE_componentWillMount() {
  //   if (!this.props.tabs.data.length && !this.props.tabs.fetching) {
  //     this.props.fetchTabs();
  //   }
  //   this.props.fetchUser();
  // }

  const retry = () => {
    dispatch(fetchTabs());
  };

  useEffect(() => {
    // if (error && error.response && [401, 402].includes(error.response.status)) {
    if (
      error &&
      (error.message.includes('401') || error.message.includes('402'))
    ) {
      history.replace('/');
    }
    if (tabs.length) {
      localStorage.setItem('tabs', JSON.stringify(tabs));
    }
  }, [history, error, tabs]);

  // componentDidUpdate(prevProps: any) {
  //   const { error, tabs } = this.props;
  //   if (error && error.response && [401, 402].includes(error.response.status)) {
  //     this.props.history.replace('/');
  //   }
  //   if (!prevProps.tabs.length && tabs.length) {
  //     localStorage.setItem('tabs', JSON.stringify(tabs));
  //   }
  // }

  const renderRoutes = (item: any, component: any, link: any) => (
    <Route key={item._id} path={`${match.url}${link}`} component={component} />
  );

  //const { tabs, match } = this.props;

  return (
    <div>
      <Header title={title} />
      <div className="container-bg-fluid">
        <div className="container-bg-left">
          <Menu />
        </div>
        <ErrorOverlay
          visible={error !== null}
          onClose={retry}
          title="Erro"
          buttonTitle="Tentar novamente"
          message="Ocorreu um erro. Verifique a sua conexão e tente novamente."
        />
        <Switch>
          <Route
            exact
            path={`${match.url}/`}
            render={() => <Redirect to={`${match.url}/home`} />}
          />
          <Route exact path={`${match.url}/home`} component={Home} />
          {(tabs.length ? tabs : previousTabs).map((item: any) =>
            renderRoutes(
              item,
              routeComponents[item.route],
              routeMap[item.route],
            ),
          )}
          <Route render={() => <Redirect to={`${match.url}/home`} />} />
        </Switch>
        <Footer />
      </div>
    </div>
  );
};

// function mapStateToProps({ userReducer, pageReducer, tabsReducer }: any) {
//   const { title } = pageReducer;
//   const { user, error } = userReducer;
//   const { tabs, fetching: fetchingTabs, error: errorTabs } = tabsReducer;
//   return { title, user, error, tabs, fetchingTabs, errorTabs };
// }

export default Main;
