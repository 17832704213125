import React, { useEffect } from 'react';
import moment from 'moment';
import 'moment/locale/pt-br';
import { SingleDatePicker } from 'react-dates';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchScheduleDates,
  fetchScheduleTimes,
  fetchRescheduleDates,
  fetchRescheduleTimes,
} from 'cdfdigital-common/build/actions';
import { scheduleActions } from 'cdfdigital-common/build/reducers/scheduleReducer';
import {
  ErrorOverlay,
  ContentContainer,
  HeaderIcon,
} from '../../../components';

interface ScheduleDateState {
  date: any;
  time: any;
  datePickerOpen: boolean;
}

const ScheduleDate: React.FC = () => {
  const location = useLocation<any>();

  const history = useHistory();

  const [state, setState] = React.useState<ScheduleDateState>({
    date: null,
    time: 0,
    datePickerOpen: false,
  });

  const { address } = useSelector<any, any>((state) => state.userReducer);
  const { service, fetching, dateList, timeList, /*date, time, */ error } =
    useSelector<any, any>((state) => state.scheduleReducer);

  let NR_CEP = '';
  if (address) {
    NR_CEP = address.NR_CEP;
  }

  const dispatch = useDispatch();

  useEffect(() => {
    moment.locale('pt-br');
    if (location.state && location.state.edit) {
      fetchRescheduleDates(location.state.ID_VISITA);
    } else {
      if (!NR_CEP) {
        history.replace('/main/schedule');
      } else {
        dispatch(fetchScheduleDates({ service, cep: NR_CEP }));
      }
    }
  }, [dispatch, history, location.state, NR_CEP, service]);

  const selectDay = (date: any) => {
    setState({ ...state, date, time: 0 });
    scheduleActions.setDate(date);
    if (location.state && location.state.edit) {
      dispatch(
        fetchRescheduleTimes({
          date: moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY'),
        }),
      );
    } else {
      dispatch(
        fetchScheduleTimes({
          service,
          cep: NR_CEP.replace('-', ''),
          date: date.format('DD/MM/YYYY'),
        }),
      );
    }
  };

  const selectTime = (time: any) => {
    setState({ ...state, time });
    let timeIndex = timeList.findIndex(
      (item: any) => item.ID_VISITA_PERIODO === time,
    );
    dispatch(scheduleActions.setTime(timeList[timeIndex]));
  };

  const renderTimes = () => {
    if ((!timeList.length && !fetching) || !state.date) {
      return null;
    }
    if (fetching) {
      return (
        <div className="time-block">
          <h3>Carregando...</h3>
        </div>
      );
    }
    return (
      <div className="time-block">
        <h3>Horários Disponíveis</h3>
        <Row>
          {timeList.map((item: any) => {
            let inicio = moment(item.HR_INICIO).format('HH:mm');
            let fim = moment(item.HR_FIM).format('HH:mm');
            return (
              <Col
                className="time-button"
                key={item.ID_VISITA_PERIODO}
                xs={12 / timeList.length}
              >
                <div
                  className={
                    state.time === item.ID_VISITA_PERIODO ? 'selected' : ''
                  }
                  onClick={() => selectTime(item.ID_VISITA_PERIODO)}
                >
                  <h4>{item.DESCRICAO}</h4>
                  <div>
                    {inicio} às {fim}
                  </div>
                </div>
              </Col>
            );
          })}
        </Row>
        <Row>
          <Col className="btn-center" xs={12}>
            <Link
              to={{
                pathname: '/main/schedule/confirmation',
                state:
                  location.state && location.state.edit
                    ? {
                        edit: true,
                        ID_VISITA: location.state.ID_VISITA,
                      }
                    : {},
              }}
              className="btn btn-primary"
              //disabled={!state.time}
            >
              Continuar
            </Link>
          </Col>
        </Row>
      </div>
    );
  };

  const renderCalendar = () => {
    if (!dateList.length) {
      return (
        <div>
          <h3>Carregando...</h3>
        </div>
      );
    }

    return (
      <div>
        <h2 className="page-title">Agendamento</h2>
        <h4 className="page-subtitle">Selecione a data da visita:</h4>
        <SingleDatePicker
          placeholder="Data"
          date={state.date}
          onDateChange={(date) => selectDay(date)}
          focused={true}
          onFocusChange={({ focused: datePickerOpen }) =>
            setState({ ...state, datePickerOpen })
          }
          id="calendar"
          hideKeyboardShortcutsPanel
          readOnly
          isDayBlocked={(day) => !dateList.includes(day.format('DD/MM/YYYY'))}
          numberOfMonths={1}
          daySize={60}
        />
        {renderTimes()}
      </div>
    );
  };

  const retry = () => {
    error && error.service === 'Datas'
      ? fetchScheduleDates({ service, cep: NR_CEP })
      : fetchScheduleTimes({
          service,
          cep: NR_CEP.replace('-', ''),
          date: state.date.format('DD/MM/YYYY'),
        });
  };

  return (
    <ContentContainer id="container-scheduleDate">
      <div className="icon-container">
        <HeaderIcon name={'laptop'} />
      </div>
      {renderCalendar()}
      <ErrorOverlay
        visible={error !== null}
        onClose={retry}
        buttonTitle="Tentar Novamente"
        title="Erro"
        message={`Houve um erro ao buscar ${
          error && error.service
        } disponíveis, tente novamente mais tarde.`}
      />
    </ContentContainer>
  );
};

export default ScheduleDate;
