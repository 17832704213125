import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import GoogleMapReact from 'google-map-react';
import { Col } from 'react-bootstrap';
import { fetchWifiLocations } from 'cdfdigital-common/build/actions';
import { WifiLocationsState } from 'cdfdigital-common/src/models';
import Marker from './Marker';
import ActiveMarker from './ActiveMarker';
import { WifiLocation } from 'cdfdigital-common/build/models';

//let dragging = false;

export interface Coordinates {
  latitude: number;
  longitude: number;
}

interface WifiGMapState {
  latitude: any;
  longitude: any;
  zoom: any;

  map: any;
}

const WifiGMap: React.FC<any> = () => {
  const [userLocation, setUserLocation] = useState<Coordinates | null>(null);

  const [activeMarker, setActiveMarker] = useState<WifiLocation | null>(null);

  const [error, setError] = useState<GeolocationPositionError | null>(null);

  const [state, setState] = useState<WifiGMapState>({
    latitude: -23.533773,
    longitude: -46.62529,
    zoom: 4,
    map: null,
  });

  const { slug } = useSelector<any, any>((state) => state.labelReducer);
  const { data: locations, fetching: locationsFetching /*, error*/ } =
    useSelector<any, WifiLocationsState>((state) => state.wifiLocationsReducer);

  const dispatch = useDispatch();

  const getUserPosition = useCallback(() => {
    navigator.geolocation.getCurrentPosition(
      ({ coords: { latitude, longitude } }) => {
        // setState({
        //   ...state,
        //   latitude,
        //   longitude,
        // });
        setUserLocation({
          latitude,
          longitude,
        });
        dispatch(fetchWifiLocations({ latitude, longitude }));
      },
      (error) => {
        console.log({ error });
        setError(error);
      },
    );
  }, [dispatch]);

  const updateMapMarkers = (map: any) => {
    dispatch(
      fetchWifiLocations({
        latitude: map.center.lat(),
        longitude: map.center.lng(),
      }),
    );
  };

  /**
   * React-GMaps events onMapCreated, onZoomChanged incorrect react lifecycle updates.
   * setInterval removes component from lifecycle to prevent unwanted updates.
   **/
  // const updateInterval = setInterval(() => {
  //   const { map, latitude, longitude, zoom } = state;
  //   if (!map || dragging) return;
  //   const center = map.getCenter();
  //   if (!center) return;
  //   const newLatitude = center.lat();
  //   const newLongitude = center.lng();

  //   // Update Zoom on change.
  //   if (map.getZoom() !== zoom) {
  //     setState({ ...state, zoom: map.getZoom() });
  //   }

  //   // Update locations based on map center change.
  //   if (latitude !== newLatitude || longitude !== newLongitude) {
  //     setState({ ...state, latitude: newLatitude, longitude: newLongitude });
  //     dispatch(
  //       fetchWifiLocations({
  //         latitude: newLatitude,
  //         longitude: newLongitude,
  //         update: true,
  //       }),
  //     );
  //   }
  // }, 500);

  useEffect(() => {
    //dragging = false;
    getUserPosition();

    // return () => {
    //   clearInterval(updateInterval);
    // };
  }, [getUserPosition /*, updateInterval*/]);

  const onMapCreated = ({ map, maps }: any) => {
    //setState({ ...state, map });

    var bounds = new maps.LatLngBounds();
    locations.forEach((marker: any) => {
      bounds.extend({
        lat: marker.location.coordinates[1],
        lng: marker.location.coordinates[0],
      });
    });
    map.fitBounds(bounds);
  };

  /**
   * Do NOT change user center when clicking on marker. (latitude, longitude)
   * InfoWindow size can push bounds if close to corner, triggering a new call when the center changes. (line 35)
   *
   * Locations must use key to find correct item on list, markers may render incorrect locations without it.
   **/
  const handleMarkerClick = (key: any) => {
    const marker = locations[key];
    setActiveMarker(marker);
  };

  const renderInfo = () => {
    if (!activeMarker) {
      return null;
    }
    return (
      <ActiveMarker
        lat={activeMarker.location.coordinates[1]}
        lng={activeMarker.location.coordinates[0]}
        marker={activeMarker}
        icon=""
      />
    );
  };

  const { zoom } = state;

  if (locationsFetching) {
    return <div>Carregando...</div>;
  }

  const center = userLocation
    ? {
        lat: userLocation.latitude,
        lng: userLocation.longitude,
      }
    : undefined;

  return (
    <Col xs={12}>
      <GoogleMapReact
        resetBoundsOnResize={true}
        style={{ width: '100%', height: '300px' }}
        //de
        defaultCenter={{
          lat: -14.235004,
          lng: -51.92528,
        }}
        center={center}
        zoom={zoom}
        bootstrapURLKeys={{
          key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '',
        }}
        //showsUserLocation
        onGoogleApiLoaded={onMapCreated}
        // onDragStart={() => {
        //   dragging = true;
        // }}
        onDragEnd={updateMapMarkers}
      >
        {userLocation && (
          <Marker
            lat={userLocation.latitude}
            lng={userLocation.longitude}
            icon={'../../current-location.png'}
          />
        )}
        {locations.map((marker: any, key: any) => (
          <Marker
            lat={marker.location.coordinates[1]}
            lng={marker.location.coordinates[0]}
            key={key}
            onClick={() => handleMarkerClick(key)}
            icon={`../../${slug}/map_pin.png`}
          />
        ))}
        {renderInfo()}
      </GoogleMapReact>
    </Col>
  );
};

export default WifiGMap;
