import React from 'react';
import {
  FormGroup,
  FormLabel,
  FormControl,
  Alert,
  FormControlProps,
} from 'react-bootstrap';
import { UseFormClearErrors, UseFormSetValue } from 'react-hook-form';

export interface FormInputProps extends FormControlProps {
  autoFocus?: boolean;
  clearErrors: UseFormClearErrors<any>;
  input: any;
  isTextarea?: boolean;
  label?: string;
  maxLength?: number;
  meta: any;
  name: string;
  normalize?: Function;
  setValue?: UseFormSetValue<any>;
}

const FormInput: React.FC<FormInputProps> = ({
  autoFocus = false,
  clearErrors,
  input,
  isTextarea,
  label,
  maxLength,
  meta,
  name,
  normalize,
  setValue,
  children,
  ...inputProps
}) => {
  const { error /*, active, visited, submitFailed*/ } = meta;

  // const getValidationState = (): 'error' | 'success' | 'warning' | null => {
  //   const hasError = error && !active && (visited || submitFailed);
  //   if (hasError) {
  //     return 'error';
  //   }
  //   return null;
  // };

  //const hasError = error && !active && (visited || submitFailed);

  // Applies maxLength & normalize to the input
  React.useEffect(() => {
    if (!maxLength && !normalize) {
      return;
    }
    if (!setValue) {
      console.error(
        `setValue must be provided for "${name}" to be used with maxLength or normalize`,
      );
      return;
    }
    let value = input.value as string;
    if (maxLength) {
      value = value.substring(0, maxLength);
    }
    if (normalize) {
      value = normalize(value);
    }
    setValue(name, value);
  }, [input.value, maxLength, name, normalize, setValue]);

  const classes = [
    'component-formInput',
    ...(error?.message ? ['has-errors'] : []),
  ];

  // Clear errors when the input is focused
  const onFocusCapture = () => {
    clearErrors && clearErrors(name);
  };

  return (
    <FormGroup
      controlId={name}
      //validationState={getValidationState()}
      className={classes.join(' ')}
    >
      {label && <FormLabel>{label}</FormLabel>}
      <FormControl
        {...inputProps}
        autoFocus={autoFocus}
        onChange={input.onChange}
        onFocus={input.onFocus}
        onBlur={input.onBlur}
        onFocusCapture={onFocusCapture}
        value={input.value}
        as={isTextarea ? 'textarea' : 'input'}
        className="field-required form-control"
      >
        {children}
      </FormControl>
      <FormControl.Feedback />
      {error && <Alert>{error.message}</Alert>}
    </FormGroup>
  );
};

export default FormInput;
