import { createSlice } from '@reduxjs/toolkit';
import { LoginState } from 'cdfdigital-common/src/models/Login';
import { logIn, logOut, tryLogin } from '../actions';

// const reducers = {
//   logIn: (state: LoginState, action: PayloadAction<string>) => {
//     state.data.token = action.payload;
//   },
// };

const initialState: LoginState = {
  data: {
    token: null,
  },
  error: null,
  fetching: false,
};

const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    resetStatus: (state) => {
      state.fetching = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(tryLogin.pending, (state) => {
        state.fetching = true;
      })
      .addCase(tryLogin.fulfilled, (state, action) => {
        state.data = action.payload;
        state.fetching = false;
      })
      .addCase(tryLogin.rejected, (state, action) => {
        state.error = action.payload || action.error;
        state.fetching = false;
      })
      .addCase(logIn.fulfilled, (state, action) => {
        state.data.token = action.payload;
      })
      .addCase(logOut.fulfilled, (state) => {
        state.data.token = null;
      });
  },
});

export const loginReducer = loginSlice.reducer;
export const loginActions = loginSlice.actions;
