import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Carousel, Button, Image } from 'react-bootstrap';
import {
  fetchIntroduction,
  fetchIntroductionLink,
} from 'cdfdigital-common/build/actions';
import appendQueryString from 'cdfdigital-common/build/helper/append-query-string';
import { useHistory } from 'react-router-dom';
import {
  IntroductionLinkState,
  IntroductionState,
} from 'cdfdigital-common/src/models';

const Introduction: React.FC = () => {
  const history = useHistory();

  const dispatch = useDispatch();

  const [state, setState] = useState({
    loginOpen: false,
    index: 0,
    direction: null,
  });

  const { data: introduction } = useSelector<any, IntroductionState>(
    (state) => state.introductionReducer,
  );

  const { data: introductionLink } = useSelector<any, IntroductionLinkState>(
    (state) => state.introductionLinkReducer,
  );

  const { ID_CLIENTE_CORPORATIVO, slug } = useSelector<any, any>(
    (state) => state.labelReducer,
  );

  useEffect(() => {
    dispatch(
      fetchIntroductionLink({
        label: slug,
      }),
    );
    dispatch(
      fetchIntroduction({
        ID_CLIENTE_CORPORATIVO,
        label: slug,
        platform: 'web',
      }),
    );
  }, [dispatch, ID_CLIENTE_CORPORATIVO, slug]);

  const handleSelect: any = (selectedIndex: any, e: any) => {
    setState({
      ...state,
      index: selectedIndex,
      //direction: e.direction,
    });
  };

  const renderIntroduction = (slug: string) => {
    if (introduction.length)
      return introduction.map((item: any) => (
        <Carousel.Item className="intro-carousel-item" key={item._id}>
          <img
            src={appendQueryString(
              item.background,
              ['tokiomarine'].includes(slug)
                ? 'w=1950&h=960&fit=crop'
                : 'w=1920&h=960&fit=crop&blend=000000&bm=darken&balph=30',
            )}
            alt={item.text}
          />
          <Carousel.Caption>
            <h1>{item.text}</h1>
            <h3>{item.details}</h3>
          </Carousel.Caption>
        </Carousel.Item>
      ));
  };

  const { index, direction } = state;

  const handleButtonClick = () => {
    if (introductionLink.link) {
      window.location.href = introductionLink.link;
    } else {
      history.push('/login');
    }
  };

  return (
    <div id="container-introduction">
      <Image className="logo" src={`/${slug}/logo.png`} />
      <Container fluid className="intro-carousel no-gutters">
        <Carousel
          activeIndex={index}
          onSelect={handleSelect}
          dir={direction || 'ltr'}
        >
          {renderIntroduction(slug)}
        </Carousel>
        <div className="btn-row">
          <Button bsPrefix="btn btn-introduction" onClick={handleButtonClick}>
            ACESSAR
          </Button>
        </div>
      </Container>
      <div className="intro-powered">
        <Image
          src={`/header-powered.png`}
          className="img-responsive"
          style={
            ['extra', 'pontofrio'].includes(slug)
              ? { filter: 'brightness(30%)' }
              : {}
          }
        />
      </div>
    </div>
  );
};

export default Introduction;
