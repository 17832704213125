import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { BiDuplicate } from 'react-icons/bi';
import { fetchSpotifyLicense } from 'cdfdigital-common/build/actions';
import copyToClipboard from '../../../helper/copy-to-clipboard';
import { UserState } from 'cdfdigital-common/src/models';

interface SpotifyItemState {
  hasLicenses: boolean;
  tokensList: any[];
  hasURL: any;
  copy?: any;
}

const SpotifyItem: React.FC<any> = () => {
  const [state, setState] = useState<SpotifyItemState>({
    hasLicenses: false,
    tokensList: [],
    hasURL: null,
  });

  //const code = React.createRef();

  const { data: user } = useSelector<any, UserState>(
    (state) => state.userReducer,
  );

  const spotifyReducer = useSelector<any, any>((state) => state.spotifyReducer);

  const noCopy = (
    <Tooltip id="tooltip">
      <strong>Copiar</strong>
    </Tooltip>
  );

  const hasCopy = (
    <Tooltip id="tooltip">
      <strong>Copiado!</strong>
    </Tooltip>
  );

  const dispatch = useDispatch();

  const hasURL = useCallback(() => {
    const link = user.CONTRATO.map((contrato: any) =>
      contrato.SOFTWARES.find(
        (item: any) => item.DS_SOFTWARE.includes('Spotify Premium') && item.URL,
      ),
    );

    return link[0] ? link[0].URL : null;
  }, [user]);

  useEffect(() => {
    setState({ ...state, hasURL: hasURL() });
  }, [state, hasURL]);

  useEffect(() => {
    if (spotifyReducer?.licenses) {
      setState({ ...state, hasLicenses: true });
    }
  }, [state, spotifyReducer?.licenses]);

  const getSpotifyLicenses = () => {
    const licenses = user.CONTRATO.map((contrato: any) =>
      contrato.SOFTWARES.filter((item: any) => {
        return (
          item.DS_SOFTWARE.includes('Spotify Premium') && !item.DS_PRODUCT_KEY
        );
      }),
    );

    if (licenses[0].length > 0) {
      dispatch(fetchSpotifyLicense(licenses[0]));
    } else {
      setState({ ...state, hasLicenses: true });
    }
  };

  // const handleDismiss = () => {
  //   setState({ ...state, copy: false });
  // };

  const handleCopy = (item: any) => {
    let aux = state.tokensList;

    if (item.DS_PRODUCT_KEY) {
      copyToClipboard(item.DS_PRODUCT_KEY);
      aux[item.DS_PRODUCT_KEY] = true;
    } else if (item.data.ACTIVATION_CODE) {
      copyToClipboard(item.data.ACTIVATION_CODE);
      aux[item.data.ACTIVATION_CODE] = true;
    }

    setState({ ...state, tokensList: aux });
  };

  const renderFromLicenses = () => {
    const { tokensList } = state;

    return spotifyReducer?.licenses.map((license: any) => {
      return (
        <div key={license.data.ACTIVATION_CODE}>
          Voucher: {license.data.ACTIVATION_CODE}
          <OverlayTrigger
            placement="top"
            overlay={
              tokensList[license.data.ACTIVATION_CODE] ? hasCopy : noCopy
            }
          >
            <button className="btn-copy" onClick={() => handleCopy(license)}>
              <BiDuplicate />
            </button>
          </OverlayTrigger>
        </div>
      );
    });
  };

  const renderFromUser = () => {
    const { tokensList } = state;

    return user.CONTRATO.map(
      (
        contrato: any, // Each contract has different softwares
      ) =>
        contrato.SOFTWARES.map(
          (
            item: any, // Each software checks if has the voucher
          ) =>
            item.DS_SOFTWARE.includes('Spotify Premium') &&
            item.DS_PRODUCT_KEY ? (
              <div className="spotify-voucher" key={item.ID_SOFTWARE_LICENCA}>
                Voucher: {item.DS_PRODUCT_KEY}
                <OverlayTrigger
                  placement="top"
                  overlay={tokensList[item.DS_PRODUCT_KEY] ? hasCopy : noCopy}
                >
                  <button className="btn-copy" onClick={() => handleCopy(item)}>
                    <BiDuplicate />
                  </button>
                </OverlayTrigger>
              </div>
            ) : null,
        ),
    );
  };

  const { hasLicenses } = state;

  if (spotifyReducer?.fetching) {
    return <div className="spotify-content">loading</div>;
  }

  if (hasLicenses && !spotifyReducer?.fetching) {
    return (
      <div className="spotify-content">
        <div>
          Utilize os vouchers abaixo e aproveite o Spotify Premium por 4 meses{' '}
        </div>

        {spotifyReducer?.licenses ? renderFromLicenses() : renderFromUser()}

        <a
          className="external-link"
          href="https://www.spotify.com/redeem"
          target="_blank"
          rel="noreferrer"
        >
          <Button>ATIVAR VOUCHERS</Button>
        </a>
      </div>
    );
  }

  return (
    <div className="spotify-content">
      {/* <a href="https://www.spotify.com/br/" target="_blank">
          <Button>ENTRAR NO SPOTIFY</Button>
        </a>

        <a onClick={() => !hasURL ? getSpotifyLicenses() : null} href={hasURL ? hasURL : null} target="_blank">
          <Button>RESGATAR LICENÇA</Button>
        </a> */}

      <div>
        Este benefício do Magalu Conecta oferece{' '}
        <a
          onClick={() => (!hasURL ? getSpotifyLicenses() : null)}
          href={state.hasURL ? state.hasURL : null}
          target="_blank"
          rel="noreferrer"
          style={{ color: 'black', textDecoration: 'underline' }}
        >
          4 meses de acesso ao Spotify
        </a>{' '}
        após este período será cobrado o valor mensal de R$16.90
      </div>
      <div>
        Em caso de dúvidas entre em contato com o nosso suporte através da aba
        de atendimento.
      </div>
    </div>
  );
};

export default SpotifyItem;
