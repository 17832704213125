import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const VideoCallSupport: React.FC = () => {
  const history = useHistory();

  useEffect(() => {
    history.replace('/');
  }, [history]);

  return (
    <div id="container-videoCallSupport">
      <div>Redirect to /</div>
    </div>
  );
};

export default VideoCallSupport;
