import { combineReducers } from 'redux';
import { accessTokenReducer } from './accessTokenReducer';
import { authInfoReducer } from './authInfoReducer';
import { pageReducer } from './pageReducer';
import { labelReducer } from './labelReducer';
import { loginReducer } from './loginReducer';
import {
  cepReducer,
  homeLinksReducer,
  introductionReducer,
  introductionLinkReducer,
  licenseReducer,
  luckyNumberDescriptionReducer,
  luckyNumberReducer,
  mediaReducer,
  newsReducer,
  serviceChannelsReducer,
  scheduleDatesReducer,
  scheduleReducer,
  scheduleTimesReducer,
  //spotifyReducer,
  storeLocationReducer,
  tabsReducer,
  termsOfServiceReducer,
  toolsReducer,
  userAddressReducer,
  userReducer,
  wifiHotspotsReducer,
  wifiLocationsReducer,
} from 'cdfdigital-common/build/reducers';

export default combineReducers({
  accessTokenReducer,
  authInfoReducer,
  cepReducer,
  homeLinksReducer,
  introductionReducer,
  introductionLinkReducer,
  labelReducer,
  licenseReducer,
  loginReducer,
  luckyNumberDescriptionReducer,
  luckyNumberReducer,
  mediaReducer,
  newsReducer,
  pageReducer,
  serviceChannelsReducer,
  scheduleDatesReducer,
  scheduleReducer,
  scheduleTimesReducer,
  //spotifyReducer,
  storeLocationReducer,
  tabsReducer,
  termsOfServiceReducer,
  toolsReducer,
  userAddressReducer,
  userReducer,
  wifiHotspotsReducer,
  wifiLocationsReducer,
});
