import axios from 'axios';

export const axiosAuth = () => {
  const token = localStorage.getItem('token');

  const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URI,
    headers: {
      ...(token && { Authorization: `Bearer ${token}` }),
    },
  });

  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (
        token &&
        error.response &&
        [401, 402].includes(error.response.status)
      ) {
        localStorage.removeItem('token');
        window.location.replace('/');
      }
      return Promise.reject(error);
    },
  );

  return instance;
};
