//PAGE
export const PAGE_SET_TITLE = 'page_set_title';

//LABEL
export const SET_LABEL_INFO = 'SET_LABEL_INFO';

//SPOTIFY
export const SPOTIFY_VOUCHERS_FETCHING = 'SPOTIFY_VOUCHERS_FETCHING';
export const SPOTIFY_VOUCHERS_FETCHING_ERROR =
  'SPOTIFY_VOUCHERS_FETCHING_ERROR';
export const FETCH_SPOTIFY_VOUCHERS = 'FETCH_SPOTIFY_VOUCHERS';
