import React from 'react';
import { IconType } from 'react-icons';

import {
  BsCalendarFill,
  BsFileTextFill,
  BsGearFill,
  BsFileEarmarkFill,
  BsHeadphones,
  BsHeartFill,
  BsGeoAltFill,
  BsPersonFill,
} from 'react-icons/bs';

import {
  FiActivity,
  FiCalendar,
  FiClipboard,
  FiFileText,
  FiHeadphones,
  FiHeart,
  FiMapPin,
  FiSettings,
  FiUser,
} from 'react-icons/fi';

interface IProps {
  iconName: string;
  iconType: string;
}

const NavIcon: React.FC<IProps> = ({ iconName, iconType }) => {
  if (iconType !== 'feather') {
    iconType = 'bootstrap';
  }
  const icon = iconMap[iconType][iconName] || iconMap[iconType]['default'];
  return icon({});
};

const iconMap: { [key: string]: { [key: string]: IconType } } = {
  bootstrap: {
    activity: FiActivity, // not available on bootstrap icons
    'calendar-check-o': BsCalendarFill,
    clipboard: BsFileTextFill,
    cog: BsGearFill,
    default: BsGearFill,
    'file-text': BsFileEarmarkFill,
    headphones: BsHeadphones,
    heart: BsHeartFill,
    'map-pin': BsGeoAltFill,
    user: BsPersonFill,
  },
  feather: {
    activity: FiActivity,
    'calendar-check-o': FiCalendar,
    clipboard: FiClipboard,
    cog: FiSettings,
    default: FiSettings,
    'file-text': FiFileText,
    headphones: FiHeadphones,
    heart: FiHeart,
    'map-pin': FiMapPin,
    user: FiUser,
  },
};

export default NavIcon;
