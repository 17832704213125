import React from 'react';
import Tools from './Tools';
import ToolsItem from './ToolsItem';
import LuckyNumber from './LuckyNumber';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

const ToolsRoutes: React.FC = () => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${match.url}/`} component={Tools} />
      <Route path={`${match.url}/tool_lucky_number`} component={LuckyNumber} />
      <Route path={`${match.url}/:id_slug`} component={ToolsItem} />
    </Switch>
  );
};

export default ToolsRoutes;
