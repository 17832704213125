import React, { MouseEventHandler } from 'react';
import { WifiLocation } from 'cdfdigital-common/build/models';
import { MarkerProps } from './Marker';
import { Coordinates } from '.';

interface ActiveMarkerProps extends MarkerProps {
  marker: WifiLocation;
  closeAction?: MouseEventHandler<any>;
}

const ActiveMarker: React.FC<ActiveMarkerProps> = ({
  icon,
  marker,
  closeAction,
}) => {
  const getMapLink = ({ latitude, longitude }: Coordinates) => {
    return `https://www.google.com/maps/dir/?destination=${latitude},${longitude}&travelmode=driving&api=1`;
  };

  return (
    <div className="marker-active">
      <div className="close" onClick={closeAction}>
        X
      </div>
      <a
        className="location-label"
        href={getMapLink({
          latitude: marker.location.coordinates[1],
          longitude: marker.location.coordinates[0],
        })}
        target="_blank"
        rel="noreferrer"
      >
        <div className="label-title">{marker.name}</div>
      </a>
    </div>
  );
};

export default ActiveMarker;
