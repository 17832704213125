import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Image } from 'react-bootstrap';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { setPageTitle } from 'cdfdigital-common/build/actions';
import { fetchTools, fetchUser } from 'cdfdigital-common/build/actions';
import appendQueryString from 'cdfdigital-common/build/helper/append-query-string';
import { ErrorOverlay, ContentContainer } from '../../../components';
import { ToolsState, UserState } from 'cdfdigital-common/src/models';

const Tools: React.FC = () => {
  const { slug } = useSelector<any, any>((state) => state.labelReducer);

  const {
    data: tools,
    fetching: toolsFetching,
    error,
    //hotspots,
    //hotspotsError,
  } = useSelector<any, ToolsState>((state) => state.toolsReducer);

  const { fetching: userFetching } = useSelector<any, UserState>(
    (state) => state.userReducer,
  );

  const fetching = toolsFetching || userFetching;

  const dispatch = useDispatch();

  const init = useCallback(() => {
    dispatch(fetchTools());
    dispatch(fetchUser());
  }, [dispatch]);

  useEffect(() => {
    init();
    setPageTitle(slug === 'tokiomarine' ? 'Serviços' : 'Ferramentas');
  }, [init, slug]);

  const renderTools = () => {
    if (fetching) {
      return null;
    }
    return _.chunk(
      tools.filter((item: any) => item.id_slug),
      2,
    ).map((row, key) => (
      <Row key={key}>
        {row.map((item: any) => (
          <Col xs={12} sm={6} key={item._id}>
            <div className="card">
              <Link to={`/main/tools/${item.id_slug}`}>
                <div className="card-image">
                  <Image
                    fluid
                    src={appendQueryString(
                      item.image,
                      'w=450&h=225&fit=crop&blend=000000&bm=darken&balph=30',
                    )}
                  />

                  <div className="card-info">
                    <h3>{item.id_slug !== 'tool_spotify' ? item.name : ''}</h3>
                    <p
                      className={item.id_slug === 'tool_spotify' ? 'mt-15' : ''}
                    >
                      {item.description}
                    </p>
                  </div>
                </div>

                <div className="cta"> Acessar </div>
              </Link>
            </div>
          </Col>
        ))}
      </Row>
    ));
  };

  const retry = () => {
    init();
  };

  return (
    <ContentContainer
      id="container-tools"
      header={slug === 'tokiomarine' ? 'Serviços' : 'Ferramentas'}
    >
      {renderTools()}
      <ErrorOverlay
        visible={error !== null}
        onClose={retry}
        buttonTitle="Tentar Novamente"
        title="Erro"
        message="Ocorreu um erro. Verifique a sua conexão e tente novamente."
      />
    </ContentContainer>
  );
};

export default Tools;
