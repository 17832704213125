import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { fetchTabs } from 'cdfdigital-common/build/actions';
import { routeMap } from '../helper/routes';
import { RecordState, Tab } from 'cdfdigital-common/src/models';
import NavIcon from './NavIcon';

interface TabRecord extends Tab {
  _id: string;
}

const Menu: React.FC = () => {
  const location = useLocation();

  const { data: tabs } = useSelector<any, RecordState<TabRecord[]>>(
    (state) => state.tabsReducer,
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchTabs());
  }, [dispatch]);

  const renderItems = (item: TabRecord, link: string) => {
    return (
      <Link to={`/main${link}`} key={item._id}>
        <Col
          xs={12}
          className={
            location.pathname.includes(link)
              ? 'menu-item selected'
              : 'menu-item'
          }
        >
          <NavIcon iconName={item.iconName} iconType="feather" />
          <span className="item-name">{item.title}</span>
        </Col>
      </Link>
    );
  };

  return (
    <div id="component-menu">
      <div className="container-bg-menu">
        <Row className="no-gutters fixed-menu">
          {tabs.map((item) => renderItems(item, routeMap[item.route]))}
        </Row>
      </div>
    </div>
  );
};

export default Menu;
