import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { setPageTitle } from '../../actions';
import { fetchTools, fetchHomeLinks } from 'cdfdigital-common/build/actions';
import { routeMap } from '../../helper/routes';
import appendQueryString from 'cdfdigital-common/build/helper/append-query-string';
import { ContentContainer, ErrorOverlay } from '../../components';
import _ from 'lodash';
import { HomeLinksState } from 'cdfdigital-common/src/models';

const Home: React.FC = () => {
  const dispatch = useDispatch();

  const boxClients = [
    { id_slug: 'cdf', color: 'black' },
    { id_slug: 'extra', color: 'black' },
    { id_slug: 'casasbahia', color: 'blue' },
    { id_slug: 'pontofrio', color: 'black' },
    { id_slug: 'pernambucanas', color: 'blue' },
    { id_slug: 'magalu', color: 'blue' },
    { id_slug: 'tokiomarine', color: 'black' },
    { id_slug: 'ticket', color: 'black' },
  ];

  const { slug: labelSlug } = useSelector<any, any>(
    (state: any) => state.labelReducer,
  );

  const {
    fetching,
    data: homeLinks,
    error,
  } = useSelector<any, HomeLinksState>((state: any) => state.homeLinksReducer);

  const client: any = _.find(boxClients, { id_slug: labelSlug });

  useEffect(() => {
    dispatch(fetchHomeLinks());
    dispatch(fetchTools());
    dispatch(setPageTitle(''));
  }, [dispatch]);

  const retry = () => {
    dispatch(fetchHomeLinks());
  };

  const renderCircleInfos = () =>
    homeLinks.map((item: any) => (
      <Link key={item._id} to={getRouteUrl(item)}>
        <Row className="item-row no-gutters">
          <Col className="card-image" xs={12} sm={5}>
            <Image
              fluid
              src={appendQueryString(
                item.imageDesktop || item.image,
                'w=750&h=450&fit=crop&blend=000000&bm=darken&balph=30',
              )}
            />
            <div className="news-label">{item.title}</div>
          </Col>
          <Col className="item-info" xs={12} sm={7}>
            <h3 className="title">{item.subtitle}</h3>
            <p>{item.description}</p>
          </Col>
        </Row>
      </Link>
    ));

  const renderSquareInfos = (client: any) =>
    homeLinks.map((item: any) => (
      <Link
        className={`squareBanner ${
          item.textPosition === 'right' ? 'rightSide' : ''
        }`}
        key={item._id}
        to={getRouteUrl(item)}
      >
        <Row className="item-row no-gutters">
          <Col className="card-image-square" xs={12}>
            <Image fluid src={item.imageDesktop || item.image} />
            <div className={client.color === 'blue' ? 'textBlue' : 'textBlack'}>
              <h3>{item.subtitle}</h3>
              <p>{item.description}</p>
            </div>
          </Col>
        </Row>
      </Link>
    ));

  const getRouteUrl = (item: any) => {
    const route = routeMap[item.route];
    const slug = item.routeSlug ? '/' + item.routeSlug : '';
    return `/main${route}${slug}`;
  };

  if (fetching) {
    return null;
  }

  return (
    <ContentContainer id="home-content">
      {['magalu', 'cdf'].includes(client.id_slug)
        ? renderCircleInfos()
        : renderSquareInfos(client)}
      <ErrorOverlay
        visible={error !== null}
        onClose={retry}
        title="Erro"
        buttonTitle="Tentar novamente"
        message="Ocorreu um erro. Verifique a sua conexão e tente novamente."
      />
    </ContentContainer>
  );
};

export default Home;
