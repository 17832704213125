import detectCurrentLabel from '../../../../helper/label';
import { axiosAuth } from '../../../../helper/axios-auth';

export const getMedexToken = async () => {
  const url = process.env.REACT_APP_API_URI + '/medexToken';

  try {
    if (
      detectCurrentLabel() === 'ticket' ||
      detectCurrentLabel() === 'tokiomarine'
    ) {
      const axios = axiosAuth();
      const response = await axios.post(url, {
        label: detectCurrentLabel(),
      });
      return response.data.token;
    } else return;
  } catch (error) {
    console.log(error);
  }
};
