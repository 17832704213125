import React from 'react';
import { Icon, ImgIcon } from './';

export interface HeaderIconProps {
  circleIcon?: string;
  className?: string;
  name: string;
  type?: string;
}

const HeaderIcon: React.FC<HeaderIconProps> = ({ name, type, className }) => {
  const renderFallback = () => {
    return (
      <div className="icon-inner-container">
        <Icon name={name} type={type} className={className} />
      </div>
    );
  };

  return (
    <div className="icon-outer-container">
      <ImgIcon icon={name} fallback={() => renderFallback()} />
    </div>
  );
};

export default HeaderIcon;
