import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { createVideoChat } from 'cdfdigital-common/build/actions';
import { ContentContainer } from '../../../../components';

const VideoCall: React.FC = () => {
  const { data: services } = useSelector<any, any>(
    (state) => state.serviceChannelsReducer,
  );

  const videoCallService = services?.find(
    (item: any) => item.type === 'VideoCall',
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(createVideoChat());
  }, [dispatch]);

  return (
    <ContentContainer>
      <Row>
        <Col xs={12}>
          <iframe
            id="iframeVideoCall"
            src={videoCallService?.contact}
            title="Atendimento via vídeo"
            allow="camera; microphone"
          />
        </Col>
      </Row>
    </ContentContainer>
  );
};

export default VideoCall;
