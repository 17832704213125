import React, { useEffect } from 'react';
import { Row, Col, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchNews, setPageTitle } from 'cdfdigital-common/build/actions';
import appendQueryString from 'cdfdigital-common/build/helper/append-query-string';
import { ErrorOverlay, ContentContainer } from '../../../components';
import { NewsState } from 'cdfdigital-common/src/models';

const News: React.FC = () => {
  const {
    data: news,
    fetching,
    error,
  } = useSelector<any, NewsState>((state) => state.newsReducer);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setPageTitle('Novidades'));
    dispatch(fetchNews());
  }, [dispatch]);

  const retry = () => {
    dispatch(fetchNews());
  };

  const cardContent = (item: any) => (
    <Row className="item-row no-gutters">
      <Col xs={12} sm={5}>
        <Image
          fluid
          src={appendQueryString(
            item.image,
            'w=750&h=450&fit=crop&blend=000000&bm=darken&balph=30',
          )}
        />
        {item.tags && <div className="news-label">{item.tags}</div>}
      </Col>
      <Col className="item-info" xs={12} sm={7}>
        <h3 className="title">{item.title}</h3>
        <p>{item.description}</p>
      </Col>
    </Row>
  );

  const renderNews = () => {
    if (fetching) {
      return null;
    }
    return news.map((item: any) => {
      if (!item.link) {
        return (
          <Link key={item._id} to={`/main/news/${item._id}`}>
            {cardContent(item)}
          </Link>
        );
      } else {
        return (
          <a key={item._id} href={item.link} target="_blank" rel="noreferrer">
            {cardContent(item)}
          </a>
        );
      }
    });
  };

  return (
    <ContentContainer id="container-news" header="Dicas">
      {renderNews()}
      <ErrorOverlay
        visible={error !== null}
        onClose={retry}
        title="Erro"
        buttonTitle="Tentar novamente"
        message="Ocorreu um erro. Verifique a sua conexão e tente novamente."
      />
    </ContentContainer>
  );
};

export default News;
