import React from 'react';

export interface IconProps {
  type?: string;
  name: string;
  className?: string;
}

const Icon: React.FC<IconProps> = ({ type = 'mdi', name, className = '' }) => {
  return (
    <i className={`component-icon ${type} ${type}-${name} ${className}`}></i>
  );
};
export default Icon;
