import React, { useEffect } from 'react';
import { Route, useRouteMatch } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setPageTitle } from '../../../actions';
import ServiceChannels from './ServiceChannels';
import Email from './Email';
import VideoCall from './VideoCall';
import CallMe from './CallMe';
import Chat from './Chat';

const ServiceChannelsIndex: React.FC = () => {
  const match = useRouteMatch();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setPageTitle('Atendimento'));
  }, [dispatch]);

  return (
    <div>
      <Route exact path={`${match.url}/`} component={ServiceChannels} />
      <Route exact path={`${match.url}/email`} component={Email} />
      <Route exact path={`${match.url}/video-call`} component={VideoCall} />
      <Route exact path={`${match.url}/call-me`} component={CallMe} />
      <Route exact path={`${match.url}/chat`} component={Chat} />
    </div>
  );
};

export default ServiceChannelsIndex;
