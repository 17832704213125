import React, { MouseEventHandler } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';

export interface ErrorOverlayProps {
  buttonTitle: string;
  message: string;
  onClose: MouseEventHandler<HTMLElement>;
  title: string;
  visible: boolean;
}

const ErrorOverlay: React.FC<ErrorOverlayProps> = ({
  visible,
  onClose,
  message,
  buttonTitle,
  title,
}) => {
  const { slug } = useSelector<any, any>((state) => state.labelReducer);

  const renderCallCenter = () => {
    return ['magalu'].includes(slug) ? (
      <Button
        bsPrefix="btn btn-primary call-center"
        href="https://cdf.cxcontact.com.br/WhatsAppbot/webchatnew/customwebchat.html?s=3-ylsRAVXLk.VFI9H_qUfeYPm4NMhnN8UFB8Zd2gczX_eTQtWg3W-aQ&username=anonimo&parceiroNegocio=magalu_conecta"
        target="_blank"
      >
        Central de Atendimento
      </Button>
    ) : null;
  };

  return (
    <Modal show={visible} onHide={() => {}}>
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>{message}</h4>
      </Modal.Body>
      <Modal.Footer>
        {renderCallCenter()}
        <Button bsPrefix="btn btn-primary" onClick={onClose}>
          {buttonTitle}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ErrorOverlay;
