import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Modal, Button } from 'react-bootstrap';
import { FormInput, HeaderIcon, ContentContainer } from '../../../components';
import validation from 'cdfdigital-common/build/helper/validation';
import { createTicket } from 'cdfdigital-common/build/actions';
import { useHistory } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';

const Email: React.FC = () => {
  const history = useHistory();

  const [messageSent, setMessageSent] = React.useState(false);

  //serviceChannelsReducer, userReducer }: any) {
  const { fetching, error: serviceError } = useSelector<any, any>(
    (state) => state.serviceChannelsReducer,
  );

  const dispatch = useDispatch();

  const onSubmit = ({ email, mensagem }: any) => {
    setMessageSent(true);
    dispatch(createTicket({ email, mensagem }));
  };

  const back = () => {
    history.replace('/main/service-channels');
  };

  const dismiss = () => {
    setMessageSent(false);
  };

  const {
    handleSubmit,
    clearErrors,
    control,
    formState: { errors },
  } = useForm<any>({
    defaultValues: {
      email: '',
      message: '',
    },
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  return (
    <ContentContainer>
      <div className="icon-container">
        <HeaderIcon name={'email-outline'} />
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col xs={12}>
            <h2 className="page-title">E-mail</h2>
            <h4 className="page-subtitle">
              Envie um e-mail e fale com a gente:
            </h4>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <div className="form-group">
              <Controller
                name="email"
                control={control}
                rules={{
                  validate: validation.email,
                }}
                render={({ field }) => (
                  <FormInput
                    input={field}
                    name="email"
                    // label='E-mail'
                    placeholder="E-mail"
                    clearErrors={clearErrors}
                    meta={{
                      error: errors.email,
                      touched: false,
                      submitFailed: '',
                    }}
                  />
                )}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <div className="form-group">
              <Controller
                name="mensagem"
                control={control}
                rules={{
                  validate: validation.notEmpty,
                }}
                render={({ field }) => (
                  <FormInput
                    input={field}
                    isTextarea={true}
                    name="mensagem"
                    // label='Mensagem'
                    placeholder="Mensagem"
                    clearErrors={clearErrors}
                    meta={{
                      error: errors.mensagem,
                      touched: false,
                      submitFailed: '',
                    }}
                    //componentClass="textarea"
                  />
                )}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="btn-center" xs={12}>
            <Button
              type="submit"
              className="btn-primary margin-y-2"
              disabled={messageSent}
            >
              {messageSent ? 'Enviando...' : 'Continuar'}
            </Button>
          </Col>
        </Row>
      </form>
      <Modal show={messageSent && !fetching} onHide={() => {}}>
        <Modal.Header>
          <Modal.Title>{!serviceError ? 'Confirmação' : 'Erro'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>
            {!serviceError
              ? 'Seu pedido de contato foi enviado! Aguarde até entrarmos em contato.'
              : 'Houve um erro com o seu pedido, tente novamente mais tarde.'}
          </h4>
        </Modal.Body>
        <Modal.Footer>
          <Button
            bsPrefix="btn btn-primary"
            onClick={serviceError === null ? back : dismiss}
          >
            Confirmar
          </Button>
        </Modal.Footer>
      </Modal>
    </ContentContainer>
  );
};

export default Email;
