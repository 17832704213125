import React, { MouseEventHandler } from 'react';

export interface MarkerProps {
  lat: number;
  lng: number;
  icon: string;
  onClick?: MouseEventHandler<any>;
}

const Marker: React.FC<MarkerProps> = ({ icon, onClick }) => {
  return (
    <div className="marker" onClick={onClick}>
      <img src={icon} alt="" />
    </div>
  );
};

export default Marker;
