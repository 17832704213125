import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Row, Col, Button, Modal } from 'react-bootstrap';
import { BsTrashFill, BsPencilFill, BsX } from 'react-icons/bs';
import moment from 'moment';
import {
  fetchSchedules,
  fetchUser,
  cancelSchedule,
} from 'cdfdigital-common/build/actions';
import {
  ErrorOverlay,
  HeaderIcon,
  ContentContainer,
} from '../../../components';

const ScheduleList: React.FC = () => {
  const history = useHistory();

  const [state, setState] = useState({
    selectedSchedule: 0,
    cancelSchedule: false,
    cancelConfirmed: false,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchSchedules());
  }, [dispatch]);

  const { schedules, error, fetching, deleteError } = useSelector<any, any>(
    (state) => state.scheduleReducer,
  );
  const { error: userError } = useSelector<any, any>(
    (state) => state.userReducer,
  );

  const capitalize = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  const retry = () => {
    dispatch(fetchSchedules());
    dispatch(fetchUser());
  };

  const editSchedule = (schedule: any) => {
    setState({ ...state, selectedSchedule: 0 });
    history.push('/main/schedule/date', {
      edit: true,
      ID_VISITA: schedule.ID_VISITA_TAKI,
    });
  };

  const renderScheduleCardContent = (schedule: any) => {
    if (state.selectedSchedule === schedule.ID_VISITA_TAKI) {
      return (
        <div className="selected">
          <Button
            className="btn-delete"
            onClick={() => {
              setState({ ...state, cancelSchedule: true });
            }}
          >
            <BsTrashFill />
          </Button>
          {(!process.env.REACT_APP_HIDE_EDIT ||
            process.env.REACT_APP_HIDE_EDIT === 'false') && (
            <Button
              className="btn-edit"
              onClick={() => {
                editSchedule(schedule);
              }}
            >
              <BsPencilFill />
            </Button>
          )}
          <Button
            className="btn-close"
            onClick={() => {
              setState({ ...state, selectedSchedule: 0 });
            }}
          >
            <BsX />
          </Button>
        </div>
      );
    }

    const data = moment(schedule.DT_VISITA, 'DD/MM/YYYY');
    console.log(schedule.DT_VISITA, data);

    return (
      <div>
        <div className="day-badge">
          <h3>{data.format('DD')}</h3>
          <h4>{data.format('MMM')}</h4>
        </div>
        <div className="schedule-info">
          <h3 className={schedule.DS_STATUS}>{schedule.DS_STATUS}</h3>
          <div>Serviço: {schedule.DS_PRODUTO}</div>
          <div>
            {data.format('ddd')} - {capitalize(schedule.DS_PERIODO)}
          </div>
        </div>
      </div>
    );
  };

  const renderSchedule = () => {
    if (fetching) {
      return <Col xs={12}>Carregando...</Col>;
    }

    return schedules
      .filter((schedule: any) => schedule.DS_STATUS !== 'Cancelado')
      .map((schedule: any) => (
        <Col key={schedule.ID_VISITA_TAKI} xs={12}>
          <div
            className="schedule-item"
            onClick={() => {
              if (state.selectedSchedule !== schedule.ID_VISITA_TAKI) {
                setState({
                  ...state,
                  selectedSchedule: schedule.ID_VISITA_TAKI,
                });
              }
            }}
          >
            {renderScheduleCardContent(schedule)}
          </div>
        </Col>
      ));
  };

  return (
    <ContentContainer id="container-scheduleList">
      <div className="icon-container">
        <HeaderIcon name={'laptop'} />
      </div>
      <Row>
        <Col xs={12}>
          <h2 className="page-title">Agendamento</h2>
        </Col>
      </Row>
      <Row>
        <Col className="schedule-new" xs={12}>
          <Link to="/main/schedule/service" className="btn btn-primary">
            Novo Agendamento
          </Link>
        </Col>
      </Row>
      <Row>{renderSchedule()}</Row>
      <ErrorOverlay
        visible={error !== null || userError !== null}
        onClose={retry}
        title="Erro"
        buttonTitle="Tentar novamente"
        message="Ocorreu um erro. Verifique a sua conexão e tente novamente."
      />
      <Modal show={state.cancelSchedule} onHide={() => {}}>
        <Modal.Header>
          <Modal.Title>
            {(deleteError && 'Erro!') ||
              (state.cancelConfirmed && !fetching && 'Sucesso') ||
              'Cancelamento'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>
            {(fetching && 'Carregando...') ||
              (deleteError &&
                'Houve um erro com o cancelamento. Tente novamente mais tarde.') ||
              (state.cancelConfirmed && 'Seu agendamento foi cancelado!') ||
              'Você realmente deseja cancelar este Agendamento?'}
          </h4>
        </Modal.Body>
        <Modal.Footer>
          {!deleteError && !state.cancelConfirmed && (
            <Button
              variant="primary"
              onClick={() => {
                cancelSchedule(state.selectedSchedule);
                setState({
                  ...state,
                  selectedSchedule: 0,
                  cancelConfirmed: true,
                });
              }}
            >
              Sim
            </Button>
          )}
          <Button
            onClick={() => {
              fetchSchedules();
              setState({
                cancelSchedule: false,
                cancelConfirmed: false,
                selectedSchedule: 0,
              });
            }}
          >
            {!deleteError && !state.cancelConfirmed ? 'Não' : 'Fechar'}
          </Button>
        </Modal.Footer>
      </Modal>
    </ContentContainer>
  );
};

export default ScheduleList;
